<template>
  <div class="faq-section-five lg-container mt-40 md-mt-50 pb-80 md-mb-50" id="faq">
    <div class="container pt-100">
      <div class="row">
        <div class="col-xl-5 col-lg-6">
          <div class="title-style-twelve">
            <h2>常见问题</h2>
            <p class="pt-25 pb-40 md-pb-30" id="wxkf">
              若有我们没有回答到你想了解的问题，欢迎与联系我们。
            </p>
            <img src="../../assets/images/media/wxkfcode.png" alt="">
          </div>
        </div>
        <div class="col-lg-6 ms-auto">
          <div id="accordionExample" class="accordion-style-five md-mt-60">
            <div v-for="item in faqData" :key="item.id" class="accordion-item">
              <div class="accordion-header" :id="item.headingNum">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#' + item.collapseNum"
                    :aria-expanded="item.expanded ? 'true' : 'false'"
                    :aria-controls="item.collapseNum"
                  >
                    {{ item.btnText }}
                  </button>
                </h5>
              </div>
              <div
                :id="item.collapseNum"
                :class="item.collapseClass"
                :aria-labelledby="item.headingNum"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p v-html="item.desc">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqArea",
  data() {
    return {
      faqData: [
        {
          id: 1,
          headingNum: "headingOne",
          collapseNum: "collapseOne",
          btnText: "万能AI是免费使用的吗？",
          desc: `万能AI免费注册即可使用。我们每月赠送每位非会员用户 10万 算力。如果你需要更多算力，可以购买会员套餐。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 2,
          headingNum: "headingTwo",
          collapseNum: "collapseTwo",
          btnText: "什么是算力？",
          desc: `「算力」是我们平台内部的虚拟币，就像你在日常生活中需要食物来维持体力一样，使用AI模型也需要算力的支持。你可以通过参与活动或购买会员套餐来获取算力，然后用它支付平台上所有AI大模型和其他增值服务的费用。获得的算力只能在平台内使用，无法提现或赠送给其他用户。`,
          expanded: true,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 3,
          headingNum: "headingThree",
          collapseNum: "collapseThree",
          btnText: "AI大模型那么多，如何选择？",
          desc: `选择AI大模型时，可以根据需求和场景来挑选，以下是各款AI大模型的简要概括。<br>▪ 百度文心3.5：兼具实力与成本优势，响应速度快，日常使用首选。<br>▪ 百度文心4.0：拥有出色的理解、生成、逻辑与记忆能力，此外还能即时获取最新的事件动态。<br>▪ 阿里通义3.5：满足日常一般的写作需求，提供流畅自然的文本生成，适合日常写作和简单内容创作。<br>▪ 阿里通义4.0：在逻辑处理和编程场景中表现出色，提供强大的代码理解和编写支持。<br>▪ 清华智谱Turbo：满足日常一般的写作需求，提供流畅自然的文本生成，适合日常写作和简单内容创作。<br>▪ 清华智谱Pro：在数学建模和计算能力上表现优异，为复杂数据处理和科学计算提供强大的支持。<br>▪ 讯飞星火V3.5：擅长文本整理和分析，能够快速准确地提取关键信息，适合文本挖掘、舆情分析等应用。<br>▪ 腾讯混元：在逻辑推理和问题解决方面表现突出，为复杂方案定制和决策支持提供有力帮助。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 4,
          headingNum: "headingFour",
          collapseNum: "collapseFour",
          btnText: "为什么没有ChatGPT等境外AI大模型？",
          desc: `首先，根据我国相关法律法规，目前境外的AI大模型尚未获得国内相关资质和许可。此外，数据安全是一个极为重要的问题。使用境外AI模型时，可能会意外泄露个人或企业的商业敏感信息至境外服务器。依据我国法律规定，使用境外AI还可能涉及违法行为，对你个人或企业带来潜在法律风险。因此，我们不提供任何境外AI模型给用户。我们只为用户提供正规的国内AI大模型，这些模型在许多场景中已经达到与境外AI大模型相媲美的水平，满足了大多数职场人士对AI大模型功能的需求。同时，你无需担心安全和法律风险问题。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 5,
          headingNum: "headingFive",
          collapseNum: "collapseFive",
          btnText: "我可以获得更多的免费算力吗？",
          desc: `当然可以！目前，你有两种方法可以免费获得额外算力：1、每日签到：每天签到即可获得 10万 算力。2、邀请朋友：每成功邀请一位朋友注册，你和你的朋友都将额外获得 30万 算力。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 6,
          headingNum: "headingSix",
          collapseNum: "collapseSix",
          btnText: "会员套餐的计费周期是按月计算的，请问是从每月的 1 号开始，还是从购买当日起算？",
          desc: `会员套餐的计费周期是从购买当日开始算起，例如，如果你在1月13日购买了会员套餐，你的计费周期将为1月13日至2月12日。值得注意的是，如果你升级套餐，计费周期将从升级当日重新开始计算。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 7,
          headingNum: "headingSeven",
          collapseNum: "collapseSeven",
          btnText: "有时候会觉得算力消耗比预期快，这是什么原因呢？",
          desc: `这通常发生在使用无场景的“AI大模型”进行连续对话时。在这种情况下，模型会将之前的所有提问和回复内容作为上下文，以生成连贯的对话内容。随着对话的延续，每次提问所需的算力会逐渐增加。为了减少算力消耗，你可以选择关闭记忆选项，或者选择适用于特定场景的模型应用。例如，如果你要发微博，可以选择“微博推文”应用来减少算力消耗。另外，不同模型的算力消耗速度也会有很大差异。例如，同样的内容，在使用百度文心4.0模型时，算力消耗速度是百度文心3.5的10倍。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 8,
          headingNum: "headingEight",
          collapseNum: "collapseEight",
          btnText: "我现在是白银会员，想升级黄金会员，当前会员套餐的算力会丢力吗？",
          desc: `不会的，升级会员套餐后，你未使用的当前会员套餐算力将会合并至新会员套餐的当月算力中，无需担心丢失。`,
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 9,
          headingNum: "headingNine",
          collapseNum: "collapseNine",
          btnText: "会员价格会调整吗？已购服务时长受影响吗？",
          desc: `是的，我们保留随着运营成本的变化不定期调整会员算力价格的权利。不过，不管价格如何变动，已购买的服务时长将不受影响。`,
          collapseClass: "accordion-collapse collapse",
        },
      ],
    };
  },
};
</script>
