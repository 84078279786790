<template>
  <TermsContent/>
</template>

<script>
import TermsContent from './TermsContent.vue';

export default {
  name:'UserTermsMain',
  components:{
    TermsContent,
}
}
</script>