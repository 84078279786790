<template>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" href="/">。</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/app-store">。</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/pricing">。</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/pricing#faq">。</a>
    </li>
  </ul>
</template>