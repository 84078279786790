<template>
  <div class="fancy-short-banner-twelve">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 col-lg-11 m-auto" data-aos="fade-up" data-aos-duration="1200">
          <div class="title-style-ten text-center">
            <h2>发挥你的真正潜力</h2>
            <p class="pt-25 pb-45">现在就去拥有万能AI</p>
          </div>
        </div>
      </div>
      <div class="hero-banner-ten">
        <div class="col-lg-10 m-auto">
          <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/chat?invite='+invite" v-if="invite" class="theme-btn-nine">创建免费账号</a>
          <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/chat'" v-else class="theme-btn-nine">创建免费账号</a>
        </div>
      </div>
    </div> <!-- /.container -->
    <img src="../../assets/images/shape/220.svg" alt="" class="shapes shape-one">
    <img src="../../assets/images/shape/221.svg" alt="" class="shapes shape-two">
  </div>


</template>

<script>
export default {
  name: 'ShortBanner',
  data() {
    return {
      invite: "",
      siteData: {
        siteName: "",
        siteLink: "zh",
        siteHost: ""
      },
    }
  },
  mounted() {
    this.invite = localStorage.getItem("invite");
    if(window["siteData"]){
      this.siteData = window["siteData"];
    } 
  },
}
</script>