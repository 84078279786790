<template>
  <PricingNewMain/>
</template>

<script>
import PricingNewMain from '../components/pricing';

export default {
  name:'PricingNew',
  components:{
    PricingNewMain,
  }
}
</script>