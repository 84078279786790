<template>
    <div class="menu-app-store" style="padding-bottom: 70px;padding-top: 150px;">

        <h3 style="text-align: center; margin-top: 50px;"><span class="text-neutral-700">应用市场</span>&nbsp;</h3>

        <p style="margin-top: 30px;" class="text-neutral-600 text-center text-base font-normal tracking-normal mt-6">探索
            100+ 个预构建 AI 应用，启动你的下一个伟大创意。
        </p>

        <div class="d-flex container" id="nav-pills-container">
            <div class="flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <div class="search-input-container position-relative d-flex align-items-center">
                    <input type="text" class="form-control search-input" placeholder="搜索" v-model="searchText"
                        style="flex: 1; padding-left: 30px; padding-right: 30px;" @keydown.enter="searchKeyWord">
                    <!-- 需要替换图标 -->
                    <img src="../../assets/images/assets/search.svg" class="bi bi-search position-absolute search-icon" style="left: 8px;" />
                    <img src="../../assets/images/assets/clean.svg" class="bi bi-x-circle position-absolute clear-icon" v-if="searchText.length > 0"
                        @click="clearSearchText" style="right: 8px;" />
                </div>

                <div class="nav-link-box">
                    <button v-for="(item, index) in menuItems" :key="item.id" class="nav-link"
                        :class="{ active: isActive(index) }" @click="activateTab(index)"
                        :id="'v-pills-tab-' + index" data-bs-toggle="pill" :href="'#v-pills-' + index" role="tab"
                        aria-controls="'v-pills-' + index" aria-selected="isActive(index)">
                         <Icon :icon="item.coverImg" class="nav-link-img"/> <span class="nav-link-text">{{ item.name }}</span>
                    </button>
                </div>
            </div>


            <!-- Tab Content -->
            <div class="tab-content" id="v-pills-tabContent"
                style="width: 100%; ">

                <div class="flex flex-col py-36 gap-2 items-center bg-neutral-150" v-if="itemArray.length === 0">
                    <img src="../../assets/images/assets/empty.svg" />
                    <span class="text-neutral-400">未找到应用</span>
                </div>

                <div class="content" v-else>

                    <div class="menuAPPcontent">
                        <div class="row d-flex g-4">
                            <div class="col-md-6 col-lg-4 col-sm-12 container-box-wrapper" v-for="(item) in itemArray"
                                :key="item.order" @click="handleItemClick(item, index)">
                                <div class="card">
                                    <div class="d-flex align-items-center p-3">
                                        <img :src="item.coverImg" class="rounded-circle img-fluid avatar" alt="Avatar"
                                            width="50" height="50">
                                            <div class="ms-auto text-end index-like" style="font-size: 14px; color: #464D6A;">
                                                <img class="index-like-img" src="../../assets/images/assets/fire.svg" />  {{ item.forkCount }}
                                            </div>
                                    </div>

                                    <p style="color: #2F3659; font-size: 16px; margin-left: 24px;">{{ item.name }}</p>

                                    <span class="line-clamp-2 min-h-[39px]">{{ item.des }}</span>

                                    <div class="card-talk">
                                        <div class="card-talk-btn">
                                            <img v-if="item.catId === '|16|'" class="card-talk-btn-img" src="../../assets/images/assets/type_2.svg" />
                                            <img v-else class="card-talk-btn-img" src="../../assets/images/assets/type_2.svg" />
                                            <span v-if="item.catId === '|16|'">对话交流</span>
                                            <span v-else>文本生成</span>
                                        </div>
                                    </div>
                                    <div class="card-button">
                                        <p class="hidden-btn">
                                            <img class="hidden-btn-img" src="../../assets/images/assets/test.svg" />
                                            试一试
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </div>

    </div>
</template>
  
<script>

import axios from 'axios';
import { Icon } from '@iconify/vue';

export default {
    components: {
        Icon
    },
    data() {
        return {
            activeTab: 0,
            menuItems: [],
            allItemData: [],
            itemArray: [],
            searchText: '',
            invite: "",
            siteData: {
                siteName: "",
                siteLink: "zh",
                siteHost: ""
            },
        };
    },
    async mounted() {
        this.initAppData();
        this.invite = localStorage.getItem("invite");
        if(window["siteData"]){
            this.siteData = window["siteData"];
        } 
    },
    methods: {
        activateTab(index) {
            this.activeTab = index;
            this.itemArray = [];
            if (this.searchText.trim().length === 0) { // 搜索框是空的
                if (this.menuItems[index].id === 1000) {
                    this.itemArray = this.allItemData.slice();
                } else {
                    
                    for (let i = 0; i < this.allItemData.length; i++) {
                        const searchPattern = `|${this.menuItems[index].id}|`;
                        if (this.allItemData[i].catId.includes(searchPattern)) {
                            this.itemArray.push(this.allItemData[i]);
                        }
                    }
                }
            } else {
                // 搜索框有内容
                for (let i = 0; i < this.allItemData.length; i++) {
                    const searchPattern = `|${this.menuItems[index].id}|`;
                    if(index === 0){
                        if (this.allItemData[i].name.includes(this.searchText.trim())) {
                            this.itemArray.push(this.allItemData[i]);
                        } 
                    } else if (this.allItemData[i].catId.includes(searchPattern) && this.allItemData[i].name.includes(this.searchText.trim())) {
                        this.itemArray.push(this.allItemData[i]);
                    }
                }
            }
        },
        isActive(index) {
            return this.activeTab === index;
        },

        handleItemClick(item, index) {
            console.log('Clicked on item:', item, index);
            // console.log('Item index:', `${this.siteData.siteHosts}/${this.siteData.siteLink}/app-store?appid=${item.id}`);
            // return 
            if(this.invite){
                window.location.href = `${this.siteData.siteHosts}/${this.siteData.siteLink}/app-store?appid=${item.id}&invite=${this.invite}`;
            } else {
                window.location.href = `${this.siteData.siteHosts}/${this.siteData.siteLink}/app-store?appid=${item.id}`;
            }
            // todo item点击事件
        },
        async initAppData() {
            try {
                const [tabResponse, appListResponse] = await Promise.all([
                    axios.get(`${window['siteData'].apiHosts}/api/app/queryCats`),
                    axios.get(`${window['siteData'].apiHosts}/api/app/list`)
                ]);
                this.menuItems = tabResponse.data.data.rows;
                this.menuItems.unshift({ name: '全部', id: 1000, coverImg: 'icon-park-outline:all-application'});

                this.allItemData = appListResponse.data.data.rows; // 将数据赋值给组件的状态变量
                this.itemArray = this.allItemData.slice();

                const { catId } = this.$route.query
                if (catId) {
                    const index = this.menuItems.findIndex(item => item.id === Number(catId)); 
                    if (index) {
                        this.activateTab(index);
                    }
                    
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async getTabData() {
            axios.get(`${window['siteData'].apiHosts}/api/app/queryCats`)
                .then(response => {
                    // console.log('=========TabData==========', response.data.data.rows)
                    this.menuItems = response.data.data.rows;
                    this.menuItems.unshift({ name: '全部', id: 0, });
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        },

        async getAppListData() {
            axios.get(`${window['siteData'].apiHosts}/api/app/list`)
                .then(response => {
                    console.log("====AppData====", response.data); // 这里会返回服务器响应的数据
                    this.allItemData = response.data.data.rows; // 将数据赋值给组件的状态变量
                    this.itemArray = this.allItemData.slice();
                    // console.log("====itemArray====", this.itemArray.length);
                })
                .catch(error => {
                    console.error('Error fetching allItemData:', error);
                });
        },

        searchKeyWord() {
            this.activeTab = 0;
            this.itemArray.length = 0;
            for (let i = 0; i < this.allItemData.length; i++) {
                if (this.allItemData[i].name.includes(this.searchText.trim())) {
                    this.itemArray.push(this.allItemData[i]);
                }
            }
        },
        clearSearchText() {
            this.searchText = ''
        },
    },

};
</script>

<style scoped>
@media (min-width: 768px) {
    .menu-app-store .container {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}
.tab-content {
    padding-left: 40px;
    padding-right: 40px;
}

@media (max-width: 768px) {
    .menu-app-store .container {
        flex-direction: column;
        
    }
    .tab-content {
        padding: 0px;
    }

    .search-input-container {
        width: 100% !important;
    }

    .nav-pills {
        height: 100px !important;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        z-index: 100;
        top: 40px !important;
    }

    .nav-link-box {
        background-color: #FFF;
        width: 100% !important;
        height: 60px !important;
        display: flex;
        overflow-y: hidden !important;
        overflow-x: auto;
        
    }
    .nav-link-box::-webkit-scrollbar { 
        width: 0 !important;
        height: 0 !important;
    }

    .nav-link {
        min-width: 110px !important;
        justify-content: center !important;
        padding-left: 0px !important;
    }

}

.menu-app-store .container {
    margin: auto;
    width: 100%;
    max-width: 1440px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 80px;
}

.text-neutral-700 {
    font-size: 48px;
    --tw-text-opacity: 1;
    color: rgb(47 54 89/var(--tw-text-opacity));
    font-weight: bold;
}

.nav-link {
    text-align: left;
    color: #0C1230;
    font-size: 16px;
    width: 214px;
    height: 40px;
    margin-top: 10px;
    line-height: 15px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    padding-left: 16px;
}

.nav-link-img {
    margin-right: 10px;
    font-size: 18px;
}

.nav-pills {
    position: sticky;
    top: 100px;
    height: calc(100vh - 115px);
    width: 220px
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #8066ff;
    background-color: rgba(128, 102, 255, .1);
}

.nav-pills .nav-link.active:hover {
    color: #8066ff !important;
}

.nav-link:hover {
    color: #0C1230 !important;
    background-color: #F4F7FF;
}

.nav-link-box {
    height: calc(100vh - 155px);
    overflow: auto;
}


.menu-app-store .nav-link-box::-webkit-scrollbar {
    width: 0px;
    /* 设置滚动条的宽度 */
}

.menu-app-store .nav-link-box::-webkit-scrollbar-thumb {
    /* 设置滚动条滑块的背景颜色 */
    background-color: rgba(128, 102, 255, 0.1);
    border-radius: 2px;
    /* 设置滚动条滑块的边角圆度 */
}

.nav-link-box::-webkit-scrollbar-track {
    /* 设置滚动条轨道的背景颜色 */
    background-color: transparent;

}

.menu-app-store .nav-link-box:hover::-webkit-scrollbar {
    width: 4px;
    height: 0;
}

.menu-app-store .search-input-container {
    position: relative;
    width: 214px;
    height: 38px;
    margin-right: 6px;
}


/* 针对具有placeholder-shown状态的input元素 */
.search-input:placeholder-shown {
    color: #D9DCEF;
    /* 自定义placeholder文本颜色 */
    font-size: 14px;
    height: 38px;
    /* 自定义placeholder文本大小 */
}
.search-input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #D9DCEF;
}

.search-input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #D9DCEF;
    opacity: 1;
    /* Firefox 需要设置opacity */
}

.search-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #D9DCEF;
}

.search-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #D9DCEF;
}

.search-input {
    /* 留出放大镜图标的空间 */
    padding-left: 36px;
    border-color: rgb(236 239 255/var(--tw-border-opacity));
    --tw-border-opacity: 1;
}

.search-icon,
.clear-icon2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: #8066ff;
}

.search-icon {
    left: 8px;
}

.clear-icon2 {
    right: 8px;
}

.form-control {
    border-radius: 0.5rem;
}

/* 输入框聚焦时（鼠标悬停）的样式 */
.form-control:focus {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255/var(--tw-border-opacity));
    --tw-shadow: 0 2px 12px 0px rgba(12, 18, 48, .04);
    --tw-shadow-colored: 0 2px 12px 0px var(--tw-shadow-color);
    box-shadow: none;
}

.form-control:hover {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255/var(--tw-border-opacity));
    --tw-shadow: 0 2px 12px 0px rgba(12, 18, 48, .04);
    --tw-shadow-colored: 0 2px 12px 0px var(--tw-shadow-color);
    box-shadow: none;
}

/* start empty */
.py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
}

.bg-neutral-150 {
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgb(250 251 255/var(--tw-bg-opacity));
}

.gap-2 {
    gap: 0.5rem;
}

.items-center {
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.flex {
    display: flex;
}

.ml-10 {
    margin-left: 2.5rem;
}

.text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(203 208 234/var(--tw-text-opacity));
    line-height: 1.25rem;
    font-weight: 400;
    font-size: .875rem;
}

/* end empty */

/* app store */
.menuAPPcontent {
    /* width: 66%; */
    /* max-width: 1280px;
    width: 90%; */
    /* margin: 0 auto; */
    /* padding-top: 32px; */
}

.container-box-wrapper:hover {
    transform: translateY(-3px);
}

.card {
    --tw-shadow: 0 4px 56px 0px rgba(128,102,255,.08);
    --tw-shadow-colored: 0 4px 56px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    /* width: 300px; */
    height: 225px;
    border: 0px;
    border: 1px solid #F4F7FF;
    border-radius: 16px;
}

.card:hover {
    --tw-shadow: 0 16px 56px 0px rgba(16, 13, 31, 0.08);
    --tw-shadow-colored: 0 6px 56px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-border-opacity: 1;
    border-color: rgb(236 239 255/var(--tw-border-opacity));
    --tw-translate-y: -0.25rem;
}

.line-clamp-2 {
    color: #7F85A4;
    font-size: 13px;
    min-height: 30px;
    overflow: hidden;
    margin-left: 24px;
    margin-top: 10px;
    line-height: 1.3em;
    -webkit-line-clamp: 2;
    margin-right: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.card-talk-btn {
    --tw-text-opacity: 1;
    color: rgb(127 133 163);
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
    margin-left: 24px;
    position: absolute;
    bottom: 20px;
    background: rgb(244 247 255);
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.card-talk-btn i {
    margin-right: 5px;
}

.card-button {
    margin: 0 auto;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
    width: 85%;
    height: 32px;
    background-color: rgb(128 102 255);
    color: white;
    text-align: center;
    font-size: 13px;
    opacity: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}
.hidden-btn {
    display: flex;
    justify-content: center;
    align-items: center;

}

.hidden-btn-img {
    width: 14px;
    margin-right: 4px;
}

.card-talk-btn-img{
    width: 12px;
    margin-right: 4px;
}

.card-button:hover .hidden-btn {
    border-radius: 5px;
    background-image: linear-gradient(to right, #8666FF, #B184FF);
}

.card-button p {
    height: 32px;
    line-height: 32px;
}

.card-button i {
    /* margin-right: 5px; */
}

.container-box-wrapper {
    position: relative;
}

.container-box-wrapper>div .hidden-btn {
    /* opacity: 0; */
}

.container-box-wrapper>div:hover .card-button {
    opacity: 1;
}

.index-like {
    display: flex;
    align-items: center;
}

.index-like-img {
    margin-right: 6px;
}
</style>