<template>
  <div class="pricing-section-nine lg-container pt-50 md-pt-50" id="pricing">
    <div class="container">
      <div class="title-style-eleven text-center mb-40 md-mb-20" data-aos="fade-up">
        <h2>定价方案</h2>
        <div class="upper-title">在万能AI一个平台就能使用各领域最先进的AI大模型</div>
        <!-- Nav tabs -->
        <div id="index-nav">
          <ul class="nav nav-pills mb-3" role="tablist">
            <li class="nav-item" v-for="(item, index) in packages" :key="index" :class="{ active: selectedTab === index }">
              <a class="nav-link" :class="{ active: selectedTab === index }" @click.prevent="activateTab(index)" href="#">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row justify-content-center">
        <div v-for="(item,index) in selectPackage.list" :key="index" class="col-lg-4 col-sm-6" data-aos="fade-up"
          :data-aos-delay="index * 200">
          <div class="pr-table-wrapper mt-40 pack-items">
            <div class="pack-name">{{ item.name }}</div>
            <div class="pack-details">{{ item.modelCount /10000 }}万 算力</div>
            <div class="top-banner d-md-flex align-items-center">
              <div class="price">¥{{ item.showPrice }}</div>
              <div v-if="item.month !== -1" >
                <em>/ 月</em>
              </div>
            </div>
            <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/user-center?invite='+invite" v-if="invite" class="trial-button tran3s">升 级</a>
            <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/user-center'" v-else class="trial-button tran3s">升 级</a>
            <ul class="pr-feature">
              <li >普通AI模型：大约{{ item.model3WordNum }}字</li>
              <li >增强AI模型：大约{{ item.model4WordNum }}字</li>
              <li >AI绘画：大约{{ item.drawNum }}张</li>
            </ul>
            <div class="trial-text">{{ item.des }}</div>
          </div>
          <!-- /.pr-table-wrapper -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: "PricingSection",
  data() {
    return {
      selectedTab: 0,
      shouldAnimate: true,
      packages:[],
      selectPackage:{},
      invite: "",
      siteData: {
        siteName: "",
        siteLink: "zh",
        siteHost: ""
      },
    };
  },
  mounted() {
    this.getPricingData();
    this.invite = localStorage.getItem("invite");
    if(window["siteData"]){
      this.siteData = window["siteData"];
    } 
  },
  methods: {
    activateTab(tabIndex) {
      this.selectPackage = this.packages[tabIndex];
      this.selectedTab = tabIndex;
      // 延时500ms
      setTimeout(() => {
        this.shouldAnimate = true;
      }, 50);
      // todo 进行分类筛选
    },
    async getPricingData() {
      axios.get(`${window['siteData'].apiHosts}/api/crami/queryPackage`)
        .then(response => {
          this.packages = response.data.data.packages;
          this.selectPackage = this.packages[this.selectedTab];
          if(this.$route.hash === '#faq' || this.$route.hash === '#wxkf') {
            this.scrollToFAQ()
          }
        })
        .catch(error => {
          console.error('Error getPricingData categories:', error);
        });
    },
    scrollToFAQ(){
      this.$nextTick(() => {
        const child = document.querySelector(`#faq`) // 需要滚动的元素
        window.scrollTo({
          top: child.scrollHeight ,
          behavior: "smooth"
        })
      })
    },
  },
};
</script>


<style scoped>
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    /* 或者 right: -100%; 如果元素是相对定位的话 */
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.upper-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* 假设你的div类名为 '.slide-in-right' */
.slide-in-right {
  animation-name: slideInFromRight;
  animation-duration: 600ms;
  /* 动画持续时间为1秒，可自定义 */
  animation-timing-function: ease-out;
  /* 可以更改动画的速度曲线 */
  animation-fill-mode: both;
  /* 保证动画结束后维持最后的关键帧样式 */
  /* 其他样式如position、display等根据需要设置 */
}

/* 对于不支持动画前缀的浏览器添加兼容性写法 */
.slide-in-right {
  -webkit-animation-name: slideInFromRight;
  -webkit-animation-duration: 600ms;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
}

.nav {
  margin: 0 auto;
  border-radius: 50px;
  padding: 5px;
  float: center;
  width: 85%;
  max-width: 1230px;
  text-align: center;
  background-color: #F4F7FF
}

.nav-link {
  color: #464d6A;
  font-size: 16px;
}

.nav-pills::-webkit-scrollbar {
  width: 0;
  /* 隐藏水平滚动条 */
  height: 0;
  /* 隐藏垂直滚动条（在这里不需要） */
}

/* 为.nav-pills添加Flex布局 */
.nav-pills {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 5px;
  -ms-overflow-style: none;
  /* IE和Edge隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox隐藏滚动条 */
}

.nav-item {
  flex: 1 0 auto;
  /* 自动分配宽度，允许收缩和扩展 */
}

.nav-item.active .nav-link{
  color: #8166FF !important;
  border-radius: 50px;
  background-color: #FFFFFF !important;
}

.nav-link.active {
  color: #0d0c0d;
  border-radius: 50px;
  background-color: #FFFFFF;
  --tw-shadow: 0 16px 56px 0px rgba(16, 13, 31, 0.08);
  --tw-shadow-colored: 0 6px 56px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

}

#index-nav .nav-link.active {
  color: #8166FF !important;
  border-radius: 50px;
  background-color: #FFFFFF !important;
}

#index-nav .nav-link:hover {
  color: #8166FF !important;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
}

.pack-items:hover {
    transform: translateY(-3px);
}

.pack-items {
  --tw-shadow: 0 4px 56px 0px rgba(128,102,255,.08);
    --tw-shadow-colored: 0 4px 56px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    /* width: 300px; */
    border: 0px;
    border: 1px solid #F4F7FF !important;
    border-radius: 16px;
}

.pack-items:hover {
  --tw-shadow: 0 16px 56px 0px rgba(16, 13, 31, 0.08);
  --tw-shadow-colored: 0 6px 56px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-border-opacity: 1;
  border-color: rgb(236 239 255/var(--tw-border-opacity));
  --tw-translate-y: -0.25rem;
}
</style>