<template>
  <PrivacyContent/>
</template>

<script>
import PrivacyContent from './PrivacyContent.vue';

export default {
  name:'UserPrivacyMain',
  components:{
    PrivacyContent,
}
}
</script>