<template>
  <PermissionsContent/>
</template>

<script>
import PermissionsContent from './PermissionsContent.vue';

export default {
  name:'UserPermissionsMain',
  components:{
    PermissionsContent,
}
}
</script>