<template>
  <div class="draft-container">
    <h1>
      <span style="line-height:1">第三方的SDK目录及说明</span></h1>
    <h3>
      <strong>
        <span style="line-height:1">一、说明</span></strong>
    </h3>
    <ul>
      <li>
        <span style="line-height:1">为保障万能AI相关功能的实现与应用安全稳定的运行，我们可能会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的。</span></li>
      <li>
        <span style="line-height:1">我们会对获取信息的合作方的代码进行严格的安全监测，以保护数据安全。</span></li>
      <li>
        <span style="line-height:1">我们对接入的相关合作方在目录中列明，合作方具体的处理情况请参见合作方的隐私政策或服务协议。</span></li>
      <li>
        <span style="line-height:1">请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。</span></li>
      <li>
        <span style="line-height:1">以下共同适用万能AI的最新版本，如有单独适用的目的将在下列列表说明。由于产品迭代升级，部分历史版本可能与当前版本情况存在差异，以实际情况为准。</span></li>
    </ul>
    <h3>
      <strong>
        <span style="line-height:1">二、第三方SDK目录
          <br>
          <br>小米推送SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：小米
        <br>SDK用途：用于进行小米手机消息推送
        <br>SDK收集的个人信息：小米设备信息、联网信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">华为消息服务</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：华为
        <br>SDK用途：用于进行华为手机消息推送
        <br>SDK收集的个人信息：设备识别信息、联网信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">阿里聚安全安全组件</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：阿里巴巴
        <br>SDK用途：辅助开发者快速完成支付接入
        <br>官网链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">支付宝SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：支付宝
        <br>包名：com.alipay.sdk、com.alibaba.one、com.alipay.apmobilesecuritysdk
        <br>SDK用途：支付、基础技术支持、安全验证
        <br>SDK收集的个人信息：设备信息、网络信息、存储权限、Android ID、设备传感器信息、获取设备IMEI、获取MAC地址、获取AndroidID
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">ShareSDK/Share sdk</span></strong>
    </h3>
    <p>
      <span style="line-height:1">包名：com.mob、com.mob.tools、MobileCrossPlatformTools、MobTools
        <br>所属机构：MobTech
        <br>SDK用途：辅助开发者快速完成分享功能接入
        <br>SDK收集的个人信息：设备识别信息、地理位置信息、获取应用列表权限、Android ID、读取IMSI、读取DEVICEID、读取设备IP
        <br>收集方式：SDK收集，为保障登录功能正常使用，应用在静默状态下或在后台运行时MobTools会读取设备IP。
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">微信分享SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：微信
        <br>SDK用途：用于将帖子分享到微信
        <br>SDK收集的个人信息：设备识别信息、获取应用列表权限
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">友盟SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：北京锐讯灵通
        <br>包名：com.uc.crashsdk、com.efs、com.umeng、UmengCommon
        <br>SDK用途：错误及崩溃信息收集
        <br>SDK收集的个人信息：设备识别信息、设备信息、mac地址信息、IMEI、Mac地址、Android ID、OAID、IMSI、地理位置信息息、Android ID、OAID、设备序列号、读取设备IP、读取SD卡数据、获取SD卡数据、获取设备IMEI、获取设备IMSI、获取WIFI名称、读取SDCard数据
        <br>收集方式：SDK收集，为保证实时性，应用在静默状态下或在后台运行时会读取设备IP；为实现数据缓存功能，提高数据统计性能，umeng SDK会获取SD卡数据，请注意，我们不会通过此权限进行缓存功能以外的操作及处理。
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">Bugly SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">包名：com.tencent.bugly
        <br>所属机构：腾讯
        <br>SDK用途：用于错误收集、崩溃分析
        <br>SDK收集的个人信息：备识别信息、设备信息、联网信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">百度统计SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：百度
        <br>SDK用途：收集使用性能数据、进行功能及体验优化
        <br>SDK收集的个人信息：设备识别信息、网络信息、设备信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">移动一键登录SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：掌淘网络
        <br>SDK用途：用于实现一键登录
        <br>SDK收集的个人信息：设备信息、联网信息、设备识别信息、读取已安装应用列表、系统运行信息、网络状态信息、IMEI、IMSI、OAID、ICCID、BSSID、读取WIFI的BSSID
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">vivo推送</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：vivo
        <br>SDK用途：用于进行vivo手机消息推送
        <br>SDK收集的个人信息：vivo手机设备信息、联网信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">OPPO推送</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：OPPO
        <br>SDK用途：用于进行OPPO手机消息推送
        <br>SDK收集的个人信息：OPPO手机设备信息、联网信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">荣耀推送SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：深圳荣耀软件技术有限公司
        <br>SDK用途：用于进行荣耀手机消息推送
        <br>SDK收集的个人信息：设备识别信息、联网信息、AAID、PushToken
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">七牛SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">包名：PLPlayerkit、PLShortVideoKit、Qiniu
        <br>所属机构：七牛云
        <br>SDK用途：图片处理
        <br>SDK收集的个人信息：设备信息、读取ANDROID ID、读取DEVICEID
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">Flutter</span></strong>
    </h3>
    <p>
      <span style="line-height:1">包名：dev.fluttercommunity、io.flutter.plugin、io.flutter
        <br>所属机构：Google
        <br>SDK用途：实现基础功能
        <br>SDK收集的个人信息：设备序列号、读取设备序列号
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">OkHttp</span></strong>
    </h3>
    <p>
      <span style="line-height:1">包名：OkHttp、OkHttp3
        <br>所属机构：开源
        <br>SDK用途：实现网络请求
        <br>SDK收集的个人信息：设备IP、读取设备IP、读取设备IP地址
        <br>收集方式：SDK收集，为保障网络请求功能正常使用，应用在静默状态下或在后台运行时会读取设备IP。
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">OAID、MSA移动安全联盟SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：移动安全工作委员会
        <br>SDK用途：用于MSA移动安全联盟设备OAID生成
        <br>SDK收集的个人信息：开放匿名设备标识符(OAID)、开发者匿名设备标识符(VAID)、应用匿名设备标识符(AAID、ad_aaid)
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">三、第三方信息共享清单说明
          <br>
          <br>友盟SDK</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：北京锐讯灵通
        <br>收集目的：收集APP运行错误、崩溃信息，协助快速定位解决用户问题
        <br>场景描述：崩溃及启动时进行数据收集
        <br>收集方式：SDK采集
        <br>共享的个人信息：设备识别信息、设备信息、mac地址信、IMEI、Mac地址、Android ID、OAID、IMSI、地理位置信息、Android ID、OAID、设备IP
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">支付宝支付</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：支付宝（中国）网络技术有限公司
        <br>收集目的：唤起支付宝APP完成支付
        <br>场景描述：用户下单后支付环节
        <br>收集方式：SDK采集
        <br>共享的个人信息：订单支付相关信息、订单安全相关设备信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
    <h3>
      <strong>
        <span style="line-height:1">微信支付</span></strong>
    </h3>
    <p>
      <span style="line-height:1">所属机构：深圳市腾讯计算机系统有限公司
        <br>收集目的：唤起微信APP完成支付
        <br>场景描述：用户下单后支付环节
        <br>收集方式：SDK采集
        <br>共享的个人信息：订单支付相关信息、订单安全相关设备信息
        <br>官网链接：https:隐私政策链接：https:</span></p>
  </div>
</template>

<style scoped>
    body{
        line-height:1;
    }
    table{
        border-collapse:collapse;
        border-spacing:0;
    }
    ul{
        list-style-type:square;
    }
    strong{
        font-weight:700;
    }
    .draft-container{
        box-sizing:border-box;
        height:100%;
        line-height:1.5;
        color:#000;
        font-size:14px;
        padding-bottom:25px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px
    }
    .draft-container p{
        margin-bottom:9px;
    }
    .draft-container table{
        width:100%;
    }
    .draft-container tr{
        text-align:center;
    }
    .draft-container td{
        border:1px solid#e4e4e5;
    }
    .draft-container a{
        color:#3370ff;
    }
    .draft-container a:hover{
        color:#0000EE;
    }
    .draft-container h1,h2,h3,h4,h5,h6{
        color:#000;
        font-weight:600;
    }
    .draft-container h1{
        margin-bottom:15px;
        margin-top:15px;
        font-size:22px;
    }
    .draft-container h2{
        margin-bottom:13px;
        margin-top:13px;
        font-size:19px;
    }
    .draft-container h3{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h4{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px
    }
    .draft-container h5{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h6{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container ul,.draft-container ol{
        padding-left:16px;
    }
    .draft-container ul ul,.draft-container ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul,.draft-container ol ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul ul,.draft-container ol ol ol ol{
        padding-left:16px;
    }
    .draft-container ol{
        list-style-type:decimal;
    }
    .draft-container ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol ol{
        list-style-type:decimal;
    }
    h3 {
        padding-top: 1.2em;
    }
</style>