<template>
  <ThirdpartiesContent/>
</template>

<script>
import ThirdpartiesContent from './ThirdpartiesContent.vue';

export default {
  name:'ThirdpartiesMain',
  components:{
    ThirdpartiesContent,
}
}
</script>