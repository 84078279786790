<template>
  <div class="draft-container">
    <h1>
      <span style="line-height:1">隐私政策</span></h1>
    <h3>
      <strong>
        <span style="line-height:1">更新日期：2024年2月14日
          <br>生效日期：2024年2月14日
          <br>
          <br></span></strong>
    </h3>
    <p>
      <span style="line-height:1">万能AI（简称“我们”）深知个人信息对你的重要性，你的信任对我们非常重要，我们将按照法律法规的规定并参照行业最佳实践保护你的个人信息及隐私安全。我们制定本“隐私政策”并特别提示：希望你在使用万能AI及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">本隐私政策将帮助你了解：</span></strong>
    </h3>
    <p>
      <span style="line-height:1">我们会遵循隐私政策收集、使用你的信息，但不会仅因你同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。
        <br>当你使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现业务功能或根据法律法规要求所必需的必要信息，你均可以拒绝提供且不影响其他功能或服务。我们将在隐私政策中逐项说明所要收集的信息。
        <br>麦克风、相册（存储）、蓝牙权限均不会默认开启，只有经过你的明示授权才会在为实现特定功能或服务时使用，你也可以撤回授权。
        <strong>特别需要指出的是，即使经过你的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集你的信息。具体请参见</strong>
        <a href="/permissions">应用权限申请与使用情况说明</a>
        <br>下文将帮你详细了解我们如何收集、使用、存储、转移（如适用）与保护个人信息；帮你了解管理你的个人信息的方式。本政策与你使用我们的服务关系密切，我们建议你仔细阅读并理解本政策全部内容，作出你认为适当的选择，有关你个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。
        <br>1.我们如何收集和使用个人信息
        <br>2.我们如何使用cookie等同类技术
        <br>3.数据使用过程中涉及的合作方、转移、公开个人信息
        <br>4.我们如何存储个人信息
        <br>5.我们如何保护个人信息的安全
        <br>6.管理你的个人信息
        <br>7.未成年人条款
        <br>8.隐私政策的修订和通知
        <br>9.联系我们</span></p>
    <h3>
      <span style="line-height:1">1.我们如何收集和使用个人信息</span></h3>
    <p>
      <span style="line-height:1">我们会按照如下方式收集你在使用服务时主动提供的，以及通过自动化手段收集你在使用功能或接受服务过程中产生的信息：
        <br>
        <strong>1.1注册、登录</strong>
        <br>a.当你注册、登录万能AI及相关服务时，你可以通过手机号创建账号并完成注册。手机号码是履行国家法律法规关于网络实名制要求的必要信息，如果你不提供手机号码用于注册、登录，我们可能无法为你提供AI对话等功能。
        <br>b.你可以使用第三方账号登录并使用万能AI，经过你的同意，我们将获取你在第三方平台注册的公开信息（头像、昵称以及你授权的其他信息），用于与万能AI账号绑定，使你可以直接登录并使用本产品和相关服务。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.2智能对话</span></strong>
    </h3>
    <p>
      <span style="line-height:1">a.我们基于生成式人工智能模型技术向你提供对话及互动服务。为了向你提供对话及互动服务，我们可能会收集你主动输入的信息（包括文字或语音）、内容指令，你在使用模型时的行为信息（包括你的点击、浏览、编辑等操作记录），以及你提供的反馈信息（包括点赞、点踩、提交反馈）。我们会对上述信息进行分析与计算，以便于更好地理解你的问题和上下文语境，从而为你提供更具有相关性的信息内容（包括文字或音视频）。
        <br>b.在经安全加密技术处理、严格去标识化且无法重新识别特定个人的前提下，我们可能会将上述所收集的数据以及模型生成的对应回复用于模型训练，不断调整优化模型的效果。如果你不希望你输入或提供的信息用于模型训练和优化，可以通过本隐私政策公示的联系方式与我们联系，要求撤回使用你的数据用于模型训练和优化。
        <br>c.你主动拍摄，或输入图片、语音时，我们会根据你输入的信息类型分别请求你授权相机、相册、麦克风权限。如你拒绝授权，将无法拍摄或无法输入图片信息和语音信息，但不影响你使用万能AI的其他功能。
        <br>d.当我们识别到你输入的提问可能与你所在的位置相关时，经过你授权，我们会收集与本服务相关的地理位置信息，以向你提供准确、相关的内容和信息。地理位置信息包括GPS传感器、WLAN接入点、蓝牙（Bluetooth）和基站等精确地理位置信息以及通过网络位置信息（如基站、IP地址及WLAN）获取的大致地理位置信息，其中精确地理位置信息是个人敏感信息，若你拒绝授权位置权限会影响定位的准确性，但不会影响其他功能与服务的正常使用，我们也仅会基于大致地理位置信息向你展示可能相关的内容或服务。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.3智能搜索</span></strong>
    </h3>
    <p>
      <span style="line-height:1">当识别到你可能有搜索意图时，我们会收集、分析你主动输入的信息（包括文字或语音），以便向你自动提供实时搜索结果。其中，部分搜索结果可能与你所在位置相关，为了展示这类搜索结果，我们会收集你的地理位置信息。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.4用户反馈与服务</span></strong>
    </h3>
    <p>
      <span style="line-height:1">a.客服及问题：为便于与你联系、尽快帮助你解决问题或记录相关问题的处理方案及结果，我们可能会保存你与我们的通信/通话记录及相关内容（包括账号信息、反馈信息、你为了证明相关事实提供的其他信息，或你留下的联系方式）。
        <br>b.当你投诉他人或被他人投诉时，为了保护你及他人的合法权益，我们可能会将你的账号/身份信息、联系方式、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。
        <br>c.问卷调查：为了提供服务及改进服务质量的合理需要，我们可能在万能AI或合作平台向你发送问卷。在分析问卷时，我们会使用你的问卷答复信息，以及你与客服联系时你提供的其他信息；如你不愿意，你也可以拒绝参与问卷调查。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.5消息通知</span></strong>
    </h3>
    <p>
      <span style="line-height:1">你知悉并同意，我们在运营中可能会通过你在使用产品及/或服务的过程中所提供的联系方式（例如：电话号码），向你同时发送一种或多种类型的通知，例如用户使用体验调研。但请你放心，如你不愿接受这些信息，你可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.6运营与安全运行/保障</span></strong>
    </h3>
    <p>
      <span style="line-height:1">
        <strong>1.6.1运营与安全保障</strong>
        <br>我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护我们服务的正常运行，保护你或其他用户或公众的合法利益免受损失，我们会收集用于维护产品或服务安全稳定运行的必要信息。
        <br>
        <strong>1.6.2设备信息与日志信息</strong>
        <br>a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（Android如AndroidID、OAID、IMSI、SIM卡信息（如ICCID）、GAID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（设备MAC地址）、IP地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、设备传感器数据（如加速度传感器）。
        <br>b.为了预防恶意程序、保障运营质量及效率，我们会收集正在运行的进程信息、页面的总体运行、使用情况与频率、页面崩溃情况、总体使用情况、性能数据。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.7收集、使用个人信息目的变更</span></strong>
    </h3>
    <p>
      <span style="line-height:1">请你了解，随着我们业务的发展，可能会对万能AI的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用你的个人信息，会再次按照法律法规及国家标准的要求以页面提示、交互流程、协议确认方式另行向你进行告知说明，并征得你的同意。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">1.8征得授权同意的例外</span></strong>
    </h3>
    <p>
      <span style="line-height:1">请你理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用你的个人信息不必事先征得你的授权同意：
        <br>a.与我们履行法律法规规定的义务相关的；
        <br>b.与国家安全、国防安全直接相关的；
        <br>c.与公共安全、公共卫生、重大公共利益直接相关的；
        <br>d.与刑事侦查、起诉、审判和判决执行等直接相关的；
        <br>e.出于维护你或他人的生命、财产等重大合法权益但又很难得到本人授权同意的；
        <br>f.你自行向社会公众公开的个人信息；
        <br>g.根据个人信息主体要求签订和履行合同所必需的；
        <br>h.从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道；
        <br>i.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
        <br>j.为开展合法的新闻报道所必需的；
        <br>k.为学术研究机构，基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
        <br>l.法律法规规定的其他情形。
        <br>
        <strong>特别提示你注意，如信息无法单独或结合其他信息识别到你的个人身份，其不属于法律意义上你的个人信息；当你的信息可以单独或结合其他信息识别到你的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他你的个人信息结合使用时，这些信息在结合使用期间，将作为你的个人信息按照本隐私政策处理与保护。</strong></span>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">2.我们如何使用cookie等同类技术</span></strong>
    </h3>
    <p>
      <span style="line-height:1">cookie和设备信息标识等同类技术是互联网中普遍使用的技术。当你使用万能AI及相关服务时，我们可能会使用相关技术向你的设备发送一个或多个cookie或匿名标识符，以收集、标识和存储你访问、使用本产品时的信息。我们承诺，不会将cookie用于本隐私政策所述目的之外的任何其他用途。我们使用cookie和同类技术主要为了实现以下功能或服务：</span></p>
    <h3>
      <strong>
        <span style="line-height:1">2.1保障产品与服务的安全、高效运转</span></strong>
    </h3>
    <p>
      <span style="line-height:1">我们可能会设置认证与保障安全性的cookie或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">2.2帮助你获得更轻松的访问体验</span></strong>
    </h3>
    <p>
      <span style="line-height:1">使用此类技术可以帮助你省去重复你填写个人信息、输入搜索内容的步骤和流程（示例：记录对话）。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">2.3 cookie的清除</span></strong>
    </h3>
    <p>
      <span style="line-height:1">大多数浏览器均为用户提供了清除浏览器缓存数据的功能，你可以在浏览器设置功能中进行相应的数据清除操作。如你进行清除，可能因为这些修改，你可能无法使用依赖于cookie由公司提供的服务或相应功能。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.数据使用过程中涉及的合作方以及转移、公开个人信息
          <br>3.1数据使用过程中涉及的合作方
          <br>3.1.1原则</span></strong>
    </h3>
    <p>
      <span style="line-height:1">
        <strong>a.合法原则：</strong>与合作方合作过程中涉及数据使用活动的，必须具有合法目的、符合法定的合法性基础。如果合作方使用信息不再符合合法原则，则其不应再使用你的个人信息，或在获得相应合法性基础后再行使用。
        <br>
        <strong>b.正当与最小必要原则：</strong>数据使用必须具有正当目的，且应以达成目的必要为限。
        <br>
        <strong>c.安全审慎原则：</strong>我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。
        <br>具体请参见：
        <a href="/thirdparties">第三方的SDK目录及说明</a></span>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">3.1.2委托处理</span></strong>
    </h3>
    <p>
      <span style="line-height:1">对于委托处理个人信息的场景，我们会与受托合作方根据法律规定签署相关处理协议，并对其个人信息使用活动进行监督。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.1.3共同处理</span></strong>
    </h3>
    <p>
      <span style="line-height:1">对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.1.4合作方的范围</span></strong>
    </h3>
    <p>
      <span style="line-height:1">若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.1.5实现功能或服务的数据使用</span></strong>
    </h3>
    <p>
      <span style="line-height:1">当你在使用万能AI中由我们的合作方提供的功能时，如在对话中提供音视频内容，我们的合作方可能会使用为实现业务目的所必需的信息，用以向你返回更具有相关性的内容或查询结果。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.1.6实现广告与推广业务的数据使用</span></strong>
    </h3>
    <p>
      <span style="line-height:1">
        <strong>a.广告推送与投放：</strong>为实现广告与推广以及相关分析服务，并优化与提升广告与推广的有效触达率，以下合作方需要使用去标识化或匿名化后的设备、网络、渠道信息、位置和标签信息：</span>
      <ul>
        <li>
          <span style="line-height:1">广告主/推广方，或提供广告与推广以及相关分析服务的合作方；</span></li>
        <li>
          <span style="line-height:1">与广告主/推广方合作的提供广告与推广以及相关分析服务的合作方。</span></li>
      </ul>
    </p>
    <p>
      <span style="line-height:1">
        <strong>b.统计分析：</strong>进行广告与推广以及提供相关分析服务的合作方可能需要使用你的设备、网络、广告与推广的点击、浏览、展示以及转化数据等信息用于分析、衡量、提升广告与推广服务的有效性。</span></p>
    <p>
      <span style="line-height:1">
        <strong>c.合作方对信息的使用：</strong>广告与推广合作方可能将上述信息与其合法获取的其他数据相结合，以优化广告与推广效果，我们会要求其对信息的使用遵循合法、正当、必要原则，保障用户合法权利不受侵犯。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.1.7实现安全与分析统计而提供信息</span></strong>
    </h3>
    <p>
      <span style="line-height:1">
        <strong>a.保障使用安全：</strong>我们非常重视账号、服务及内容安全，为保障你和其他用户的账号与财产安全，使你和我们的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、账号及日志信息。为确保用户账号安全，在我们识别出你登录账号所使用的手机号码有二次放号风险时，我们会将你的手机号码、账号注册的时间提供给通信运营商做二次号查验，以保障你的账号安全。
        <br>
        <strong>b.分析产品情况：</strong>为分析我们产品的使用和表现情况，我们的合作方可能需要使用该产品使用情况（崩溃、闪退）、设备标识信息、应用总体安装使用情况等信息。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.2转移</span></strong>
    </h3>
    <p>
      <span style="line-height:1">随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，你的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方继续履行个人信息处理者的义务，若继受方变更原先的处理目的、处理方式的，我们将要求继受方重新征得你的授权同意。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.3公开</span></strong>
    </h3>
    <p>
      <span style="line-height:1">a.我们不会公开你的个人信息，除非遵循国家法律法规规定或者获得你的同意。我们公开你的个人信息会采用符合行业内标准的安全保护措施。
        <br>b.对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的信息。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">3.4依法豁免征得同意提供、公开的个人信息</span></strong>
    </h3>
    <p>
      <span style="line-height:1">请你理解，在下列情形中，根据法律法规及国家标准，我们向合作方提供、公开你的个人信息无需征得你的授权同意：
        <br>a.为订立、履行你作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
        <br>b.为履行法定职责或者法定义务所必需；
        <br>c.与国家安全、国防安全直接相关的；
        <br>d.与刑事侦查、起诉、审判和判决执行等直接相关的；
        <br>e.为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
        <br>f.为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
        <br>g.依照相关法律规定处理你自行公开或者其他已经合法公开的个人信息；
        <br>h.从合法公开披露的信息中收集个人信息的；
        <br>i.法律、行政法规规定的其他情形。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">4.我们如何存储个人信息
          <br>4.1存储地点</span></strong>
    </h3>
    <p>
      <span style="line-height:1">我们依照法律法规的规定，将在境内运营过程中收集和产生的你的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格遵守中国的相关法律、监管政策，并会遵循相关国家规定或者征求你的同意。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">4.2存储期限</span></strong>
    </h3>
    <p>
      <span style="line-height:1">我们仅在为提供万能AI服务之目的所必需的期间内保留你的个人信息，例如：
        <br>
        <ul>
          <li>
            <span style="line-height:1">手机号码：当你使用手机号码注册登录时，我们需要持续保留你的手机号码，以便于向你提供正常的服务、应对你可能的查询和客诉并保障你的账号和系统安全。</span></li>
          <li>
            <span style="line-height:1">你输入的信息、反馈的信息、点赞、点踩及相关信息，在你未撤回、删除或未注销账号期间，我们会保留相关信息用于向你提供持续的业务功能。
              <br>
              <br></span></li>
        </ul>如果你注销帐户、主动删除个人信息或超出必要的期限后，我们将对你的个人信息进行删除或匿名化处理，但以下情况除外：
        <ul>
          <li>
            <span style="line-height:1">遵从法律法规有关信息留存的要求。</span></li>
          <li>
            <span style="line-height:1">出于财务、审计、争议解决等目的需要合理延长期限的。</span></li>
        </ul>
      </span>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">5.我们如何保护个人信息的安全</span></strong>
    </h3>
    <p>
      <span style="line-height:1">5.1我们非常重视你个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护你的个人信息，防止你提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
        <br>5.2我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护你的个人信息，并使用安全保护机制防止你的个人信息遭到恶意攻击。
        <br>5.3我们会建立专门的安全部门、安全管理制度、数据安全流程保障你的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问你的个人信息，并适时对数据和技术进行安全审计。
        <br>
        <strong>5.4尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。
          <br>5.5你知悉并理解，你接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议你采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码及相关个人信息透露给他人。</strong>
        <br>5.6我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向你告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知你，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
        <br>5.7你一旦离开【万能AI】及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护你在【万能AI】及相关服务之外的软件、网站提交的任何个人信息，无论你登录、浏览或使用上述软件、网站是否基于【万能AI】的链接或引导。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.管理你的个人信息</span></strong>
    </h3>
    <p>
      <span style="line-height:1">我们非常重视你对个人信息的管理，并尽全力保护你对于你个人信息的查阅、复制、更正、补充、删除、撤回同意授权、注销账号、投诉举报以及设置隐私功能等权利，以使你有能力保障你的隐私和信息安全。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.1改变或撤回授权范围</span></strong>
    </h3>
    <p>
      <span style="line-height:1">你可以在设备本身的操作系统中，
        <strong>关闭麦克风、相册（存储）、蓝牙权限，改变同意范围或撤回你的授权。</strong>撤回授权后我们将不再收集与这些权限相关的信息。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.2你的个人信息权利</span></strong>
    </h3>
    <p>
      <span style="line-height:1">如果你或其他有权主体对相关法律法规赋予的个人信息权利的行使，有任何主张、要求或者疑问，你可以通过（wn@enai.im）与我们联系，我们将尽快审核所涉问题，并在验证你的用户身份后的十五个工作日内回复。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.3注销账号</span></strong>
    </h3>
    <p>
      <span style="line-height:1">你可以在【我】-【设置】-【账号设置】-【删除账号】注销账号。在你注销账号前，我们将验证你的个人身份、安全状态、设备信息等。你知悉并理解，注销账号是不可逆的行为，当你注销账号后，我们将删除有关你的相关信息或进行匿名化处理，但法律法规另有规定的除外。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.4意见反馈和投诉举报</span></strong>
    </h3>
    <p>
      <span style="line-height:1">你可以按照我们公示的制度进行投诉或举报。如果你有任何意见或你认为你的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，你可以通过（wn@enai.im）与我们联系。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.5访问隐私政策</span></strong>
    </h3>
    <p>
      <span style="line-height:1">你可以在我们网站的首页底部访问隐私政策。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6.6停止运营并向你告知</span></strong>
    </h3>
    <p>
      <span style="line-height:1">如我们停止运营，我们将及时停止收集你个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知你，并对所持有的你的个人信息进行删除或匿名化处理。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">7.未成年人条款</span></strong>
    </h3>
    <p>
      <span style="line-height:1">我们非常重视对未成年个人信息的保护。万能AI主要面向成年人。
        <br>7.1若你是未满18周岁的未成年人，在使用本产品及相关服务前，应在你的父母或其他监护人的监护、指导下共同阅读并同意本隐私政策。
        <br>
        <strong>7.2若你是未满14周岁的未成年人的监护人，在使用万能AI及相关服务前，应为你的被监护人阅读并同意本隐私政策。</strong>
        <br>7.3我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用或披露未成年人的个人信息；如果我们发现在未事先获得可证实的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。
        <br>7.4若你是未成年人的监护人，当你对你所监护的未成年人的个人信息有其他疑问时，请通过本隐私政策公示的联系方式与我们联系。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">8.隐私政策的修订和通知</span></strong>
    </h3>
    <p>
      <span style="line-height:1">8.1为了给你提供更好的服务，万能AI及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经你明确同意，我们不会削减你依据当前生效的本隐私政策所应享受的权利。
        <br>8.2本隐私政策更新后，我们会在万能AI发出更新版本，并在更新后的条款生效前通过官方网站公告或其他适当的方式提醒你更新的内容，以便你及时了解本隐私政策的最新版本。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">9.联系我们</span></strong>
    </h3>
    <p>
      <span style="line-height:1">9.1万能AI由深圳市梦之舵信息技术有限公司及/或关联方提供，如果你对个人信息保护问题有投诉、建议、疑问，你可以将问题发送至（wn@enai.im），我们核查并验证你的用户身份后会及时反馈你的投诉与举报。
        <br>9.2如对本隐私政策内容有任何疑问、意见或建议，你可通过我们网站的对话页面的投诉反馈入口或通过（wn@enai.im）与我们联系。</span></p>
  </div>
</template>

<style scoped>
    body{
        line-height:1;
    }
    table{
        border-collapse:collapse;
        border-spacing:0;
    }
    ul{
        list-style-type:square;
    }
    strong{
        font-weight:700;
    }
    .draft-container{
        box-sizing:border-box;
        height:100%;
        line-height:1.5;
        color:#000;
        font-size:14px;
        padding-bottom:25px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px
    }
    .draft-container p{
        margin-bottom:9px;
    }
    .draft-container table{
        width:100%;
    }
    .draft-container tr{
        text-align:center;
    }
    .draft-container td{
        border:1px solid#e4e4e5;
    }
    .draft-container a{
        color:#3370ff;
    }
    .draft-container a:hover{
        color:#0000EE;
    }
    .draft-container h1,h2,h3,h4,h5,h6{
        color:#000;
        font-weight:600;
    }
    .draft-container h1{
        margin-bottom:15px;
        margin-top:15px;
        font-size:22px;
    }
    .draft-container h2{
        margin-bottom:13px;
        margin-top:13px;
        font-size:19px;
    }
    .draft-container h3{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h4{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px
    }
    .draft-container h5{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h6{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container ul,.draft-container ol{
        padding-left:16px;
    }
    .draft-container ul ul,.draft-container ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul,.draft-container ol ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul ul,.draft-container ol ol ol ol{
        padding-left:16px;
    }
    .draft-container ol{
        list-style-type:decimal;
    }
    .draft-container ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol ol{
        list-style-type:decimal;
    }
    h3 {
        padding-top: 1.2em;
    }
</style>