<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <Header/>
    <PricingSection/>
    <FaqArea />
    <ShortBanner/>
    <FooterThree/>
  </div>
</template>

<script>
import Header from "../common/Headers/Header.vue";
import PricingSection from './Pricing-section.vue';
import FaqArea from "./FaqArea.vue";
import ShortBanner from '../newhome/ShortBanner.vue';
import FooterThree from '../common/Footers/FooterThree.vue';

export default {
  name:'PricingMain',
  components:{
    Header,
    PricingSection,
    FaqArea,
    ShortBanner,
    FooterThree
}
}
</script>