<template>
  <NewHomeMain/>
</template>

<script>
import NewHomeMain from '../components/newhome';

export default {
  name:'NewHome',
  components:{
    NewHomeMain,
  }
}
</script>