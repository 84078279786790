<template>
  <UserPrivacyMain/>
</template>

<script>
import UserPrivacyMain from '../components/privacy';

export default {
  name: 'UserPrivacy',
  components: {
    UserPrivacyMain,
  }
}
</script>