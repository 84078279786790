<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <Header />
    <HeroBanner />
    <Features />
    <HomeAppStore />
    <ShortBanner />
    <FooterThree />
  </div>
</template>

<script>
import Header from "../common/Headers/Header.vue";
import HeroBanner from './HeroBanner.vue';
import Features from './features.vue';
import ShortBanner from './ShortBanner.vue';
import FooterThree from '../common/Footers/FooterThree.vue';
import HomeAppStore from './HomeAppStore.vue';

export default {
  name: 'NewhomeMain',
  components: {
    Header,
    HeroBanner,
    Features,
    ShortBanner,
    FooterThree,
    HomeAppStore,
  }
}
</script>