import { createApp } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import router from "./routes";
import "bootstrap";
import "./index.css";
import App from "./App.vue";

let app = createApp(App);
app.use(router);
app.use(VueEasyLightbox);
app.mount("#app");
// TODO: 设置用户端的域名
// window["AI-HOSTS"] = "https://wnai.xiaoenai.net"
window["AI-HOSTS"] = "https://ai.wan-neng.com"
