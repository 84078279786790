<template>
  <UserPermissionsMain/>
</template>

<script>
import UserPermissionsMain from '../components/permissions';

export default {
  name: 'UserPermissions',
  components: {
    UserPermissionsMain,
  }
}
</script>