<template>
  <div class="fancy-feature-thirtySeven lg-container" id="feature">
    <div class="container">
      <div class="img-meta mt-80 mb-65 md-mt-50 md-mb-50" data-aos="fade-up">
        <img src="../../assets/images/media/newhome_chatbot.png" alt="" class="w-100">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesArea'
}
</script>