<template>
  <footer class="theme-footer-three pt-100">
    <div class="top-footer">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-4 col-12 footer-about-widget" data-aos="fade-up" data-aos-duration="1200">
            <h5 class="footer-title">万能AI</h5>
            <p class="font-rubik">你的一站式AI助手。涵盖对话、分析、写作、翻译、编程、设计等多种功能，让你工作效率提升10倍。</p>
          </div> <!-- /.about-widget -->
          <div class="col-lg-2 col-md-4 footer-list" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
            <h5 class="footer-title">关于</h5>
            <ul>
              <li><a href="/terms">用户协议</a></li>
              <li><a href="/privacy">隐私政策</a></li>
              <li><a :href="'/'+siteData.siteLink+'/pricing#faq'">在线客服</a></li>
            </ul>
          </div> <!-- /.footer-list -->
          <div class="col-lg-3 col-md-4 address-list" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <h5 class="footer-title">联系方式</h5>
            <p class="font-rubik">深圳市南山区南海大道3003号阳光华艺大厦A座14层</p>
            <ul class="info">
              <li><a href="mailto:wn@enai.im">合作邮箱：wn@enai.im</a></li>
            </ul>
          </div> <!-- /.footer-list -->
        </div> <!-- /.row -->
      </div> <!-- /.container -->
    </div> <!-- /.top-footer -->

    <div class="container">
      <div class="bottom-footer-content">
        <div class="row">
          <div class="col-lg-8 ms-auto">
            <div class="d-md-flex align-items-center justify-content-between">
              <p class="copyright text-center">
                深圳市梦之舵信息技术有限公司<br>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030002002417" target="_blank">粤公网安备44030002002417号</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备11051615号</a><br>
                增值电信业务经营许可证：粤B2-20211510<br>
                wan-neng.com 2024 © All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div> <!-- /.bottom-footer -->
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterThree',
  data() {
    return {
      siteData: {
        siteName: "",
        siteLink: "zh",
        siteHost: ""
      },
    }
  },
  mounted() {
    if(window["siteData"]){
      this.siteData = window["siteData"];
    }
  }
}
</script>