<template>
  <div class="hero-banner-ten">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 col-md-8 m-auto">
          <h1 class="hero-heading">轻松工作，享受生活</h1>
          <p class="hero-sub-heading">用AI让你的工作效率提升10倍</p>
        </div>
        <div class="col-xl-9 m-auto">
          <p class="sing-in-call pb-30">
              国内正规AI模型，任意选用
          </p>
        </div>
      </div>
      <div class="col-lg-10 m-auto">
        <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/chat?invite='+invite" v-if="invite" class="theme-btn-nine">立即使用</a>
        <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/chat'" v-else class="theme-btn-nine">立即使用</a>
      </div>
      <div class="info" style="font-size: 12px;">
        *每月免费提供 <span style="color: #9373EA;font-weight: bold;">10万</span> 基础算力
      </div>
    </div>
    <div class="icon-box-one"><img src="../../assets/images/model/xunfei.png" alt="科大讯飞星火"></div>
    <div class="icon-box-two"><img src="../../assets/images/model/ali.png" alt="阿里通义千问"></div>
    <div class="icon-box-three"><img src="../../assets/images/model/360.png" alt="360智脑"></div>
    <div class="icon-box-four"><img src="../../assets/images/model/huawei.png" alt="华为盘古"></div>
    <div class="icon-box-five"><img src="../../assets/images/model/baidu.png" alt="百度文心一言"></div>
    <div class="icon-box-six"><img src="../../assets/images/model/sensetime.png" alt="商汤日日新"></div>
    <div class="icon-box-seven"><img src="../../assets/images/model/tx.png" alt="腾讯混元"></div>
    <div class="icon-box-eight"><img src="../../assets/images/model/zhipu.png" alt="清华智谱AI"></div>
  </div>
</template>

<script>
export default {
  name: 'HeroBanner',
  data() {
    return {
      invite: "",
      siteData: {
        siteName: "",
        siteLink: "zh",
        siteHost: ""
      },
    }
  },
  mounted() {
    this.invite = localStorage.getItem("invite");
    if(window["siteData"]){
      this.siteData = window["siteData"];
    } 
  },
}
</script>