<template>
  <div>
    <div :class="`${isSticky ? `theme-main-menu sticky-menu theme-menu-one fixed 
    ${center_white ? 'center-white' : ''}` 
    : `theme-main-menu sticky-menu theme-menu-one ${center_white ? 'center-white' : ''}`}`">
      <div :class="`d-flex align-items-center ${center_white ? 'justify-content-center' : 'justify-content-between'}`">
        <div>
          <router-link :to="'/'+siteData.siteLink" class="logo">
            <img class="logo-img" src="../../../assets/images/logo/default.png" alt="万能AI" />
            <div  
              id="resizableText"  
              :style="{
                fontSize: initialFontSize + 'px',  
                lineHeight: '1.2em',  
                overflow: 'hidden',   
                whiteSpace: 'nowrap',
                fontWeight: 'bold'  
              }"  
            >  
              {{ siteData.siteName }}
            </div>  
          </router-link>
        </div>
        <nav id="mega-menu-holder" class="navbar navbar-expand-lg">
          <div class="nav-container">
          <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'" @click="menuActive = !menuActive">
            <span></span>
          </button>
            <div class="navbar-collapse collapse" id="collapsibleNavbar">
              <div class="d-lg-flex align-items-center">
                <!-- nav menu start -->
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" :href="'/'+siteData.siteLink">首页</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :href="'/'+siteData.siteLink+'/app-store'">应用市场</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :href="'/'+siteData.siteLink+'/pricing'">定价</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :href="'/'+siteData.siteLink+'/pricing#faq'">帮助</a>
                  </li>
                </ul>
                <!-- nav menu end -->

                <ul class="right-button-group d-flex align-items-center justify-content-center">
                  <li>
                    <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/chat?invite='+invite" class="signUp-action" v-if="invite">进入使用</a>
                    <a :href="siteData.siteHosts+'/'+siteData.siteLink+'/chat'" class="signUp-action" v-else>进入使用</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// import NavMenu from './NavMenu.vue';

export default {
  name: "HeaderComponent",
  props:{center_white:Boolean},
  components:{
  },
  data() {
    return {
      isSticky: false,
      menuActive: false,
      invite: "",
      siteData: {
        siteName: "",
        siteLink: "zh",
        siteHost: ""
      },
      maxSize: 134, // 最大跨度  
      initialFontSize: 27, // 初始字体大小  
      lineHeight: 1.2, // 行高系数  
    }
  },
  computed: {  

  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true;
      }
      else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    String.prototype.pxWidth = function(font) {
      // re-use canvas object for better performance
      var canvas = String.prototype.pxWidth.canvas || (String.prototype.pxWidth.canvas = document.createElement("canvas")),
          context = canvas.getContext("2d"); 

      font && (context.font = font);
      var metrics = context.measureText(this);

      return metrics.width;
    }

    window.addEventListener('scroll', this.handleSticky)
    this.invite = localStorage.getItem("invite");

    if(window["siteData"]){
      this.siteData = window["siteData"];
      
      const fontSize = this.initialFontSize;
      for(var i = fontSize; i > 0; i--){
        console.log(this.siteData.siteName.pxWidth(`bold ${i}px Robot`))
        if(this.siteData.siteName.pxWidth(`bold ${i}px Robot`)>230) {
          this.initialFontSize = i
        } else {
          return
        }
      }
    }
  },
};
</script>

<style type="text/css">
.logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #2E3659;
  width: 178  px;
}

.logo-img {
  width: 34px;
  margin-right: 10px;
}
</style>