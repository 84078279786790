<template>
  <div class="draft-container">
    <h1>
      <span style="line-height:1">用户协议</span></h1>
    <h3>
      <strong>
        <span style="line-height:1">1、导言</span></strong>
    </h3>
    <p>
      <span style="line-height:1">欢迎你使用“万能AI”软件及相关服务！
        <br>“万能AI”软件及相关服务，系指深圳市梦之舵信息技术有限公司及/或关联方（下称“公司”）以网页、应用程序（可能含不同版本）、小程序、供第三方网站和应用程序使用软件开发工具包（SDK）、应用程序编程接口（API）以及随技术发展出现的创新形态方式向你提供的产品与服务，包括但不限于以“AI交互对话”为核心功能以及其他功能的平台。本用户协议是你与公司之间就注册、登录、使用（以下统称“使用”）本软件及相关服务所订立的协议（以下简称“本协议”）。
        <br>为了更好地为你提供服务，请你在开始使用本软件及相关服务之前，认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可的条款、法律适用和争议解决条款等。
        <strong>其中，免除或者限制责任条款等重要内容将以加粗形式提示你注意，你应重点阅读。</strong>
        <br>如你不同意本协议，这将导致我们无法为你提供完整的产品和服务，你也可以选择停止使用。
        <strong>如你自主选择同意或使用本软件及相关服务，则视为你已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与本软件及相关服务相关的协议和规则的约束。</strong>
        <br>如对本协议内容有任何疑问、意见或建议，你可通过本软件的反馈与投诉入口与我们联系。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">2、本软件及相关服务</span></strong>
    </h3>
    <p>
      <span style="line-height:1">2.1本软件及相关服务的主要适用人群是成年人，如你未满18周岁，请在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用本软件及相关服务。
        <br>
        <strong>本软件及相关服务依托于生成式人工智能模型，并可能同时依托于其他模型、服务，以向你提供人工智能生成技术服务及其他服务，仅供你参考，你不应将输出的内容作为专业建议、商业用途或用于其他目的。若涉及对你或者相关方可能会产生重大影响的情形（例如与医疗、财务、投资、保险等有关的场景或目的），建议你咨询相关专业人士。本软件的输出不应成为你进一步作为或不作为的依据。你根据输出的内容所作出的任何判断或者据此作出的后续相关操作行为，所带来的后果和责任均由你自行承担，包括因对输出的内容的真实性、准确性、可靠性、不侵权或满足特定目的的依赖而产生的风险。你应科学理性认识和依法使用生成式人工智能技术。</strong>
        <br>2.2为更好的提升用户体验及服务、或为安全保障，公司将不定期提供本软件及相关服务的部分或全部更新或改变（包括但不限于软件/服务修改、升级、替换、功能强化、开发新服务等）。我们会在可行的情况下以适当的方式提示你；
        <br>2.3你可以随时停止使用本软件及相关服务，尽管公司对此表示遗憾。公司也可能随时停止向你提供服务，或随时对本软件及相关服务增加或设置新的限制。
        <br>2.4除非得到公司事先明示书面授权，你不得以任何形式对本软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。
        <br>2.5你理解，使用本软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等），一旦你在终端设备中访问本软件及相关服务，即视为你使用本软件及相关服务。为充分实现本软件及相关服务的全部功能，你可能需要将终端设备联网，你理解由你承担所需要的费用（如流量费、上网费等）。
        <br>2.6公司许可你一项个人的、可撤销的、不可转让的、非独占地和非商业的合法使用本软件及相关服务的权利。本协议未明示授权的其他一切权利仍由公司保留，你在行使该些权利前须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。
        <br>
        <strong>2.7公司非常重视人工智能模型生成内容的质量，将努力采取合理的安全措施过滤不当内容。如果你发现本软件及相关服务输出的内容或返回的处理结果不合理、有瑕疵或其他问题，欢迎并感谢你随时通过本软件及相关服务的反馈入口wn@enai.im进行反馈。</strong>
        <br>
        <strong>如你发布的内容被相关权利人投诉侵权并被处理，且你不认可处理结果，你应根据公司提示向公司提交不侵权声明及相关权利证明材料进行申诉，公司同样将会依据相关法律规定及时处理你的申诉。</strong></span>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">3、关于“账号”</span></strong>
    </h3>
    <p>
      <span style="line-height:1">3.1本软件及相关服务为你提供了注册通道，你有权选择合法的字符组合作为自己的账号，并自行设置符合安全要求的密码。你设置的账号、密码、收到的验证码，是你用以登录并以注册你的用户身份使用本软件及相关服务的凭证。
        <br>本软件及相关服务的核心功能是[AI对话]，需你登录账号后方可使用。
        <br>3.2账号注销
        <br>在需要终止使用本软件账号服务时，符合以下条件的：
        <br>（1）你仅能申请注销你本人的账号，并依照本软件的流程进行注销；
        <br>（2）你仍应对你在注销账号前且使用本软件及相关服务期间的行为承担相应责任；
        <br>（3）注销成功后，账号记录、功能等将无法恢复或提供。
        <br>如你需要注销你的账号，请按提示进行注销。
        <br>
        <strong>3.3你理解并承诺，你所设置的账号不得违反国家法律法规及公司的相关规则，你的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像或其他足以让人引起混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。公司有权对你提交的信息进行审核。你在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。</strong>
        <br>
        <strong>3.3你理解并承诺，你所设置的账号不得违反国家法律法规及公司的相关规则，你的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像或其他足以让人引起混淆的方式）开设账号，不得恶意注册账号（包括但不限于频繁注册、批量注册账号等行为）。公司有权对你提交的信息进行审核。你在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。</strong>
        <br>
        <strong>3.4你注册的的账号仅限于你本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果公司发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，公司有权立即暂停或终止向该注册账号提供服务，并有权永久禁用该账号。</strong>
        <br>3.5你有责任维护个人账号、密码、验证码的安全性与保密性，并对你以注册账号名义所从事的活动承担全部法律责任，包括但不限于你在本软件及相关服务上进行的任何数据修改、言论发表、款项支付等操作行为可能引起的一切法律责任。你应高度重视对账号、密码、验证码的保密，在任何情况下不向他人透露。若发现他人未经许可使用你的账号或发生其他任何安全漏洞问题时，你应当立即通知公司。
        <br>3.6如你在丢失账号、遗忘密码或泄露验证码时，你可遵照向公司及时申诉请求找回。你理解并认可，公司的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。公司特别提醒你应妥善保管你的账号、密码，及验证码。当你使用完毕后，应安全退出。如因你保管不当等自身原因或其他不可抗因素导致遭受盗号或密码丢失、验证码被泄露，你将自行承担相应责任。
        <br>
        <strong>3.7在注册、使用和管理账号时，你应保证注册账号时填写的身份信息的真实性，请你在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括你的姓名及电子邮件地址、联系电话、联系地址等）。依照国家相关法律法规的规定，为使用本软件及相关服务的部分功能，你需要填写真实的身份信息，请你按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若你提交的材料或提供的信息不准确、不真实、不规范或公司有理由怀疑为错误、不实、不合法的资料，则公司有权拒绝为你提供相关功能，你可能无法使用本软件及相关服务或在使用过程中部分功能受到限制。</strong>
        <br>3.8当你完成本软件的账号注册、登录并进行合理和必要的身份验证后，你可随时浏览、修改自己提交的个人身份信息。你理解并同意，出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，你可能无法修改注册时提供的初始注册信息及其他验证信息。你也可以申请注销账号，公司会在完成个人身份、安全状态、设备信息、侵权投诉等方面的合理和必要的验证后协助你注销账号，并依照你的要求删除有关你账号的一切信息，法律法规另有规定的除外。
        <br>3.9你理解并同意，为了充分使用账号资源，如你在注册后未及时进行初次登录使用或连续超过六个月未登录账号使用等情形，公司有权收回你的账号。如你的账号被收回，你可能无法通过你此前持有的账号登录并使用本软件及相关服务，你该账号下保存的任何个性化设置和使用记录将无法恢复。在收回你的账号之前，公司将以适当的方式向你作出提示，如你在收到相关提示后六个月内仍未登录、使用账号，公司将进行账号收回。
        <br>如你的账号被收回，你可以通过注册新的账号登录、使用本软件及相关服务。你注册新账号并登录、使用的行为仍受到本协议相关条款的约束。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">4、用户个人信息保护</span></strong>
    </h3>
    <p>
      <span style="line-height:1">公司与你一同致力于你个人信息（即能够独立或与其他信息结合后识别你身份的信息）的保护，保护用户个人信息是公司的基本原则之一。在使用本软件及相关服务的过程中，你可能需要提供你的个人信息，以便公司向你提供更好的服务和相应的技术支持。公司将依法保护你浏览、查阅、复制、更正、修改、补充、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与本软件及相关服务相匹配的技术措施及其他安全措施保护你的个人信息，更多关于用户个人信息保护的内容，请参见
        <a href="/privacy">《隐私政策》</a>。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">5、用户行为规范</span></strong>
    </h3>
    <p>
      <span style="line-height:1">5.1用户行为要求
        <br>你应当对你使用本软件及相关服务的行为负责，除非法律允许或者经公司事先书面许可，你使用本软件及相关服务不得具有下列行为：
        <br>
        <strong>5.1.1使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对本软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</strong>
        <br>
        <strong>5.1.2利用或针对本软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：</strong>
        <br>（1）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；
        <br>（2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
        <br>（3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
        <br>（4）使用未经许可的数据或进入未经许可的服务器/账号；
        <br>（5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
        <br>（6）未经许可，企图探查、扫描、测试本软件系统或网络的弱点或其它实施破坏网络安全的行为；
        <br>（7）企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
        <br>（8）伪造TCP/IP数据包名称或部分名称；
        <br>（9）对本软件及相关服务进行反向工程、反向汇编、反向编译、翻译或者以其他方式尝试发现本软件的源代码、模型、算法和系统的源代码或底层组件；
        <br>（10）恶意注册本软件及相关服务的账号，包括但不限于频繁、批量注册账号；
        <br>（11）开发、服务与公司和、或关联公司有竞争可能性的应用程序、产品、服务或模型；
        <br>（12）违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。
        <br>5.1.3未经原始用户的同意，向任何非原始用户显示或以其他方式提供任何用户信息；
        <br>5.1.4未经授权冒充他人或获取对本软件及相关服务的访问权；
        <br>5.1.5利用或针对本软件及相关服务从事诈骗、欺诈、误导或欺骗性活动，包括但不限于假冒或将智能体生成的答案表示为人工生成、剽窃或学术不诚实、或网络钓鱼，和或从事其他任何违法违规活动；
        <br>5.1.6以可能侵犯任何第三方隐私的任何方式使用本软件及相关服务，例如非法收集或披露个人身份信息或教育、财务或其他受保护的记录，例如地址、电话号码、电子邮件地址、个人身份证件中的号码和特征（例如身份证号、社保账号、护照号码）或信用卡号码；
        <br>5.1.7如果公司有理由认为你利用生成式人工智能服务从事违法活动或可能违反上述约定的，公司可独立进行判断并有权在不事先通知你，公司自行采取处置措施，处置措施包括但不限于警示、限制功能、暂停或者终止向你提供服务，并追究相关法律责任。
        <br>5.2信息内容规范
        <br>5.2.1公司致力于文明、理性、友善、高质量的交流。在推动业务发展的同时，不断加强相应的信息安全管理能力，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。
        <br>
        <strong>5.2.2你输入、输出/生成、制作、评论、上传、发布、传播的信息应自觉遵守法律、行政法规，遵守公共秩序，尊重社会公德和伦理道德、社会主义核心价值观、国家利益、尊重他人合法权益、道德风尚和信息真实性等“七条底线”要求，否则公司有权立即采取相应处理措施。你不得自行或允许、协助任何人利用本软件及相关服务从事下述行为：</strong>
        <br>（1）反对宪法确定的基本原则的；
        <br>（2）危害国家安全和利益，泄露国家秘密的；
        <br>（3）颠覆国家政权，推翻社会主义制度、损害国家形象、煽动分裂国家、破坏国家统一和社会稳定的；
        <br>（4）损害国家荣誉和利益的；
        <br>（5）宣扬恐怖主义、极端主义的；
        <br>（6）宣扬民族仇恨、破坏民族团结的；
        <br>（7）宣扬歧视、仇恨，利用特定群体基于其年龄、社会、身体或精神特征的任何脆弱性，以实质性扭曲属于该群体的人的行为，造成或可能造成该人或另一人的身体或心理伤害，包括但不限于民事歧视、宗教歧视、国籍歧视、性别歧视、性取向歧视、年龄歧视、职业歧视、健康歧视、地域歧视;
        <br>（8）从事或协助非法或高风险活动，例如：军事和战争、发展武器、爆炸物或危险材料、关键基础设施（如运输、能源）的管理或运营、创建或分销管控物质或服务、生成或传播与自残有关的信息、具有高经济损害风险的活动，含赌博、自动确定信贷资格、就业和教育决策；
        <br>（9）破坏国家宗教政策，宣扬邪教和封建迷信的；
        <br>（10）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；
        <br>（11）散布、传播、宣扬暴力、淫秽、色情、露骨、赌博、凶杀、恐怖或者教唆犯罪的；
        <br>（12）利用、伤害或企图利用或伤害未成人、损害未成年人身心健康、侵害未成年人合法权益的；
        <br>（13）侮辱、诽谤、冒犯、辱骂、激怒他人、欺凌或控制他人，侵害他人合法权益的；
        <br>（14）对他人进行暴力恐吓、威胁，实施人肉搜索的；
        <br>（15）涉及他人隐私、个人信息或资料的；
        <br>（16）散布污言秽语，损害社会公序良俗的；
        <br>（17）违反商业道德，侵害商业秘密、侵犯他人隐私权、名誉权、荣誉权、肖像权、知识产权、贸易管制等合法权益内容的；
        <br>（18）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；
        <br>（19）使用本网站常用语言文字以外的其他语言文字评论的；
        <br>（20）与所评论的信息毫无关系的；
        <br>（21）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；
        <br>（22）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；
        <br>（23）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的，包括但不限于以下情形：
        <br>i.任何暴力和/或自残行为内容；
        <br>ii.任何威胁生命健康、利用刀具等危险器械表演的危及自身或他人人身及/或财产权利的内容；
        <br>iii.怂恿、诱导他人参与可能会造成人身伤害或导致死亡的危险或违法活动的内容；
        <br>（24）其他违反法律法规、政策及公序良俗、干扰本软件及相关服务正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
        <br>5.3你可向公司投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，公司将及时受理和处理你的投诉举报，以共同营造风清气正的网络空间。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">6、信息内容使用规范</span></strong>
    </h3>
    <p>
      <span style="line-height:1">6.1未经公司书面许可，任何用户、第三方不得自行或授权、允许、协助任何他人对本软件及相关服务中信息内容进行如下行为：
        <br>（1）复制、读取、采用本软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；
        <br>（2）擅自编辑、整理、编排本软件及相关服务的信息内容后在本软件及相关服务的源页面以外的渠道进行展示；
        <br>（3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对本软件及相关服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响；
        <br>（4）其他非法获取或使用本软件及相关服务的信息内容的行为。
        <br>6.2未经公司书面许可，任何用户、第三方不得以任何方式（包括但不限于盗链、冗余盗取、非法抓取、模拟下载、深度链接、假冒注册等）直接或间接盗取本软件及相关服务的文字、图片、视频等信息内容，或以任何方式（包括但不限于隐藏或者修改域名、平台特有标识、用户名、以及专利、版权、商标或其他所有权声明等）删除、隐匿或改变相关信息内容的权利管理电子信息、或以任何方式删除、隐匿、改变本软件及相关服务上显示或其中包含的任何专利、版权、商标或其他所有权声明。
        <br>6.3经公司书面许可后，任何用户、第三方对本软件及相关服务的信息内容的分享、转发等行为，还应符合以下规范：
        <br>（1）对抓取、统计、获得的相关数据，未经公司事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；
        <br>（2）不得对本软件及相关服务的源网页进行任何形式的任何改动，包括但不限于本软件及相关服务的首页（profile页面）链接等入口，也不得对本软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；
        <br>（3）应当采取安全、有效、严密的措施，防止本软件及相关服务的信息内容被第三方通过包括但不限于“蜘蛛（spider）”程序等任何形式进行非法获取；
        <br>（4）不得把相关数据内容用于公司书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用；
        <br>（5）向任何第三人分享、转发、复制本软件及相关服务信息内容的行为，应当遵守公司为此制定的其他规范和标准。
        <br>
        <strong>6.4你不得利用基于深度学习等新技术新应用制作、发布、传播虚假信息。你在发布或传播利用基于深度学习、虚拟现实等新技术新应用生成的信息时，应当以显著方式予以标识或提示。你不应删除或者以令人误导的方式修改本软件基于深度学习、虚拟现实等新技术新应用添加的标识。</strong></span>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">7、服务的变更、中断和终止</span></strong>
    </h3>
    <p>
      <span style="line-height:1">7.1你理解并同意，公司提供的本软件及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向你提供服务，确保服务的连贯性和安全性。你理解，公司不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用本软件及服务以及其他的损失和风险。
        <br>7.2你理解并同意，公司为了整体服务运营、平台运营安全的需要，有权视具体情况决定服务/功能设置、范围，修改、中断、中止或终止本软件及相关服务。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">8、知识产权</span></strong>
    </h3>
    <p>
      <span style="line-height:1">8.1在你使用本软件及相关服务期间，你可以向本软件及相关服务提交文本、音频或其他内容或信息（合称“输入”），并接收响应你的输入而生成的内容（称“输出”、“生成”）。在你和公司之间，在适用法律允许的范围内，你提供的输入的知识产权归属于你或者依法享有这些知识产权的权利人。公司不主张输出内容的所有权；
        <br>但是，为避免疑问，如输入和/或输出本身包含了公司享有知识产权或其他合法权益的内容，则前述输入和/或输出的相应权利仍由公司享有，不因包含在输出当中而改变其权属。
        <br>
        <strong>你理解并同意，对于你通过本软件及相关服务输入、生成、发布、传播的信息内容之全部或部分（合称信息内容），你授予公司和/或关联方一项免费的、全球范围内的、永久的许可，允许公司和/或关联方可以使用你提供的信息内容来优化模型和服务。上述许可是可以转让的，也是可以进行分许可或再许可的。
          <br>你理解并承诺，输入均应为你享有知识产权或已获取权利人合法授权的内容，不存在任何违反适用的法律法规、侵犯他人合法权益（包括但不限于著作权、专利权、商标权等知识产权及人格权、个人信息权益等其他权益）、违反公序良俗的内容。
          <br>8.2除本协议8.1条另有规定外，公司在本软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权与相关权益属于公司所有。公司提供本软件服务时所依托的软件的著作权、专利权及其他知识产权均归公司、公司的关联方或相应的其他权利人所有。未经公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、“蜘蛛”等程序或设备监视、复制、传播、展示、镜像、上载、下载）本软件及相关服务中的内容。
          <br>8.3你理解并同意，对于你通过本软件及相关服务输入、上传、发布、传播的内容之全部或部分（合称信息内容），你授予公司和/或关联方使用此等信息内容，用于公司和/或公司品牌有关的宣传、推广、相关研究。</strong>
        <br>8.4公司为本软件开发、运营提供技术支持，并对本软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。
        <br>
        <strong>8.5请你在任何情况下都不要私自使用公司的包括但不限于“万能AI”等在内的任何商标、服务标记、商号、域名、网站名称、公司徽标(LOGO)、URL或其他显著品牌特征等（以下统称为“标识”）。未经公司事先书面同意，你不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于你违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由你承担全部法律责任。</strong></span>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">9、违约处理</span></strong>
    </h3>
    <p>
      <strong>
        <span style="line-height:1">9.1针对你违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除信息内容或评论、短期禁止发布内容或评论、限制账号部分或者全部功能直至终止提供服务、永久关闭账号等措施，对于因此而造成你无法正常使用账号及相关服务、无法正常获取你账号内资产或其他权益等后果，公司不承担任何责任。公司有权公告处理结果，且有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，公司将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容公司有权不予恢复。
          <br>9.2因你违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，你应当自行处理并承担全部可能由此引起的法律责任。因你的违法、侵权或违约等行为导致公司及其关联方、控制公司、继承公司向任何第三方赔偿或遭受国家机关处罚的，你还应足额赔偿公司及其关联方、控制公司、继承公司因此遭受的全部损失。
          <br>9.3公司尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权、肖像权等合法权益。你保证，在使用本软件及相关服务时输入、生成、上传、制作、评论、发布或通过其他方式传播的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利和/或合法权益。否则，公司有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，你应自行处理并承担全部可能由此引起的法律责任；如因你的侵权行为导致公司及其关联方、控制公司、继承公司遭受损失的（包括经济、商誉等损失），你还应足额赔偿公司及其关联方、控制公司、继承公司遭受的全部损失。</span></strong>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">10、免责声明</span></strong>
    </h3>
    <p>
      <strong>
        <span style="line-height:1">1、10.1你理解并同意，本软件及相关服务可能会受多种因素的影响或干扰，公司不保证(包括但不限于)：
          <br>10.1.1本软件及相关服务完全适合用户的使用要求；
          <br>10.1.2本软件及相关服务不受干扰，及时、安全、可靠或不出现任何错误；用户经由公司取得的任何软件、服务或其他材料符合用户的期望；
          <br>10.1.3本软件及相关服务中任何错误都将能得到更正。
          <br>10.2如有涉嫌借款、投融资、理财或其他涉财产的信息、帐户密码、广告或推广等信息，请你谨慎对待并自行进行判断，对你因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。
          <br>10.3你理解并同意，在使用本软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于自然灾害(如洪水、地震、台风等)、政府行为、战争、罢工、骚乱等。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力造成的暂停、中止、终止服务或造成的任何损失，公司在法律法规允许范围内免于承担责任。
          <br>10.4公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。
          <br>10.5你理解并同意，关于本软件及相关服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。你对本软件及相关服务的使用行为应自行承担相应风险。
          <br>10.6你理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果你已经理解并同意自行承担。
          <br>10.7在任何情况下，即使公司已被告知此类损害的可能性，公司或关联公司或许可方均不对任何直接、间接、后果性、惩罚性、偶然性、附带性、特殊性、后果性或刑罚性、惩戒性的损害承担责任，包括但不限于因你使用本软件及相关服务而遭受的利润损失、商誉损失、数据损失或其他损失。除法律法规另有明确规定外，公司对你承担的全部责任，无论因何原因或何种行为方式，始终不超过你在使用本软件及相关服务期间支付给公司的费用(如有)。
          <br>10.8本软件提供的信息和服务仅供参考，不构成任何担保或承诺。我们不保证本软件的信息和服务的准确性、可靠性、完整性、有效性、及时性、适用性。用户使用本软件的信息和服务所产生的风险由用户自行承担。</span></strong>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">11、未成年人使用条款</span></strong>
    </h3>
    <p>
      <strong>
        <span style="line-height:1">11.1若你是未满18周岁的未成年人，你应在监护人监护、指导并获得监护人同意的情况下，认真阅读并同意本协议后，方可使用本软件及相关服务。
          <br>11.2公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并应在取得监护人的同意以及在监护人指导下正确使用本软件及相关服务。
          <br>11.3未成年用户及其监护人理解并确认，如因你违反法律法规、本协议内容，则你及你的监护人应依照法律规定承担因此而可能导致的全部法律责任。
          <br>11.4未成年人用户特别提示：
          <br>11.4.1青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。
          <br>11.4.2青少年用户必须遵守《全国青少年网络文明公约》：
          <br>（1）要善于网上学习，不浏览不良信息；
          <br>（2）要诚实友好交流，不侮辱欺诈他人；
          <br>（3）要增强自护意识，不随意约会网友；
          <br>（4）要维护网络安全，不破坏网络秩序；
          <br>（5）要有益身心健康，不沉溺虚拟时空。
          <br>11.5为更好地保护未成年人隐私权益，公司提醒你，严格遵守相关法律法规，慎重发布包含未成年人素材的内容，一经发布，即视为你已获得权利人同意在本软件及相关服务中展示未成年人的肖像、声音等信息，且允许公司依据本协议使用、处理该等与未成年人相关的内容。</span></strong>
    </p>
    <h3>
      <strong>
        <span style="line-height:1">12、退款保障</span></strong>
    </h3>
    <p>
      <span style="line-height:1">如果由于本公司的原因导致你无法使用服务，你可在首次购买服务后的24小时内申请取消服务并全额退款。如果不符合上述要求，你的退款申请仍将被考虑，但最终决定权由公司保留。退款仅通过原支付渠道进行退款，并在申请提交后的30天内处理。如需申请退款，请通过以下电子邮件联系我们：wn@enai.im。本退款保障仅适用于通过本软件直接订阅服务的用户。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">13、软件服务的局限性以及对你的特别提示</span></strong>
    </h3>
    <p>
      <span style="line-height:1">13、生成式人工智能可以为社会带来当前和潜在的好处，但是，由于技术限制或伦理问题，我们也十分关注模型被滥用的可能。如你选择继续使用本软件，你应充分知悉并同意以下：
        <br>13.1鉴于机器学习和人工智能的性质，本软件难以保证模型生成内容的真实性、准确性、可靠性，本软件可能会提供不正确、虚假的输出。输出仅供一般信息和参考之用，不构成你应该依赖的信息或建议，也不应成为你采取或避免采取任何行动的依据。你应该根据你的需求评估任何输出的准确性。在根据输出采取或避免采取任何行动之前，应当严肃考虑进一步咨询专业人士的建议。例如，你参与的专业领域、行业的问答互动中，本软件不提供诊疗咨询服务、不提供医疗结果解释，其生成内容不能视为真正的医疗建议，若有身体不适请及时就医，谨遵医嘱；本软件不提供投资咨询业务，其生成内容不能作为决策投资的依据；本软件不提供保险咨询服务，其生成内容不能作为购买保险的依据；本软件不提供用于司法、执法的服务；本软件的服务不应被视为互联网新闻信息服务。
        <br>13.2我们非常重视模型的内容安全问题，将努力采取合理的安全措施来过滤模型产出的不当/有害内容，保障其输出结果尽量符合规范预期，但无法完全排除仍有失误出现的情况。如果你遇到此种情况，请及时与我们反馈，我们将及时处理虚假、危险、偏见信息，以进一步优化模型。
        <br>13.3你应对你输入的内容负责，应确保你输入的内容未侵害他人权利，且请勿输入涉嫌违法、不良的内容。
        <br>13.4特别提示，你不得利用基于深度学习等新技术新应用制作、发布、传播虚假信息。你在发布或传播利用基于深度学习等新技术新应用生成的信息时，应当以显著方式予以标识或提示。你不应删除或者以令人误导的方式修改本软件基于深度学习、虚拟现实等新技术新应用添加的标识。</span></p>
    <h3>
      <strong>
        <span style="line-height:1">14、其他</span></strong>
    </h3>
    <p>
      <span style="line-height:1">
        <strong>14.1本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。若本协议之任何规定因与中华人民共和国的法律抵触而无效或不可执行，则这些条款应在不违反法律的前提下尽可能按照接近本协议原条文目的之原则进行解释和使用，且本协议其它规定仍应具有完整的效力及效果。
          <br>14.2本协议的签订地为中华人民共和国广东省深圳市南山区。若你与公司发生任何争议，双方应尽量友好协商解决，如协商不成，你同意应将争议提交至本协议签订地的人民法院诉讼解决。
          <br>14.3为给你提供更好的服务或国家法律法规、政策调整、技术条件、产品功能等变化需要，公司会适时对本协议进行修订，修订内容构成本协议的组成部分。为方便你及时了解本协议的最新版本，你可以在本软件及相关服务的相关页面中查阅。如你继续使用本软件及相关服务，即表示你已同意接受修订后的本协议内容。
          <br>你对修改后的协议条款存有异议的，请立即停止登录或使用本软件及相关服务。若你继续登录或使用本软件及相关服务，即视为你认可并接受修改后的协议条款。
          <br></strong>14.4公司有权依本软件及相关服务或运营的需要单方决定，安排或指定其关联方、控制公司、继承公司或公司认可的第三方公司继续运营本软件。并且，就本协议项下涉及的某些服务，可能会由公司的关联方、控制公司、继承公司或公司认可的第三方公司向你提供。你知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
        <br>14.5本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。
        <br>14.6你和公司均是独立的主体，在任何情况下本协议不构成公司对你的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。</span></p>
  </div>
</template>

<style scoped>
    body{
        line-height:1;
    }
    table{
        border-collapse:collapse;
        border-spacing:0;
    }
    ul{
        list-style-type:square;
    }
    strong{
        font-weight:700;
    }
    .draft-container{
        box-sizing:border-box;
        height:100%;
        line-height:1.5;
        color:#000;
        font-size:14px;
        padding-bottom:25px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px
    }
    .draft-container p{
        margin-bottom:9px;
    }
    .draft-container table{
        width:100%;
    }
    .draft-container tr{
        text-align:center;
    }
    .draft-container td{
        border:1px solid#e4e4e5;
    }
    .draft-container a{
        color:#3370ff;
    }
    .draft-container a:hover{
        color:#0000EE;
    }
    .draft-container h1,h2,h3,h4,h5,h6{
        color:#000;
        font-weight:600;
    }
    .draft-container h1{
        margin-bottom:15px;
        margin-top:15px;
        font-size:22px;
    }
    .draft-container h2{
        margin-bottom:13px;
        margin-top:13px;
        font-size:19px;
    }
    .draft-container h3{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h4{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px
    }
    .draft-container h5{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h6{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container ul,.draft-container ol{
        padding-left:16px;
    }
    .draft-container ul ul,.draft-container ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul,.draft-container ol ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul ul,.draft-container ol ol ol ol{
        padding-left:16px;
    }
    .draft-container ol{
        list-style-type:decimal;
    }
    .draft-container ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol ol{
        list-style-type:decimal;
    }
    h3 {
        padding-top: 1.2em;
    }
</style>