<template>
  <UserThirdpartiesMain/>
</template>

<script>
import UserThirdpartiesMain from '../components/thirdparties';

export default {
  name: 'UserThirdparties',
  components: {
    UserThirdpartiesMain,
  }
}
</script>