<template>
    <!-- <div class="main-page-wrapper font-gordita overflow-hidden"> -->
      <Header/>
      <MenuAppStore/>
      <ShortBanner/>
      <FooterThree/>
    <!-- </div> -->
  </template>
  
  <script>
  import Header from "../common/Headers/Header.vue";
  import MenuAppStore from './MenuAppStore.vue';
  import ShortBanner from '../newhome/ShortBanner.vue';
  import FooterThree from '../common/Footers/FooterThree.vue';
  
  export default {
    name:'app-store',
    components:{
      Header,
      MenuAppStore,
      ShortBanner,
      FooterThree
  }
  }
  </script>