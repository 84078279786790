<template>
  <AppStore />
</template>

<script>
import AppStore from '../components/app-store';

export default {
  name: 'appStore',
  components: {
    AppStore,
  }
}
</script>