<template>
  <UserTermsMain/>
</template>

<script>
import UserTermsMain from '../components/terms';

export default {
  name: 'UserTerms',
  components: {
    UserTermsMain,
  }
}
</script>