<template>
    <div class="justify-content-around" style="padding-bottom: 70px;">
        <!-- Bootstrap Nav Pills -->
        <div id="index-nav">
            <ul class="nav nav-pills mb-3" role="tablist">
                <li class="nav-item" v-for="(item, index) in tabs" :key="item.id">

                    <a class="nav-link" :class="{ active: activeTab === index }" @click.prevent="activateTab(index, item.id)"
                        href="#">
                        <Icon :icon="item.coverImg" class="nav-link-img" v-if="index !== tabs.length - 1"/> 
                        <!-- <img :src="item.coverImg" class="nav-link-img" v-if="index !== tabs.length - 1"/>  -->
                        {{ item.name }}
                        <Icon :icon="item.coverImg" class="nav-link-img" style="margin-left: 2px;" v-if="index === tabs.length - 1"/> 
                    </a>
                </li>
            </ul>
        </div>


        <div class="appstore-container">
            <div class="row d-flex g-4" :class="{ 'slide-in-right': shouldAnimate }">
                <div class="col-md-4 col-lg-3 col-sm-6 container-box-wrapper" v-for="(item, index) in itemArray"
                    :key="item.order" @click="handleItemClick(item)">
                    <div class="card">
                        <div class="d-flex align-items-center p-3">

                            <img :src="item.coverImg" class="rounded-circle img-fluid avatar" alt="Avatar" width="50"
                                height="50">

                            <div class="ms-auto text-end index-like" style="font-size: 14px; color: #464D6A;">
                                <img class="index-like-img" src="../../assets/images/assets/fire.svg" />  {{ item.forkCount }}
                            </div>
                        </div>

                        <p style="color: #2F3659; font-size: 16px; margin-left: 24px;">{{ item.name }}</p>

                        <span class="line-clamp-2 min-h-[39px]">{{ item.des }}</span>

                        <div class="card-talk">
                            <div class="card-talk-btn">
                                <img v-if="item.catId === '|16|'" class="card-talk-btn-img" src="../../assets/images/assets/type_2.svg" />
                                <img v-else class="card-talk-btn-img" src="../../assets/images/assets/type_2.svg" />
                                <span v-if="item.catId === '|16|'">对话交流</span>
                                <span v-else>文本生成</span>
                            </div>
                        </div>
                        <div class="card-button">
                            <p class="hidden-btn">
                                <img class="hidden-btn-img" src="../../assets/images/assets/test.svg" />
                                试一试
                            </p>
                        </div>

                    </div>
                    <div class="mask" v-if="index === itemArray.length - 1" @click.stop="jumpToAppStore">
                        <img class="index-like-img" src="../../assets/images/assets/more.svg" /> 
                        <p>查看更多</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>

import axios from 'axios';
import { Icon } from '@iconify/vue';

export default {
    components: {
        Icon
    },
    data() {
        return {
            activeTab: 0,
            activeCatId: 0,
            stWidth: { 'width': '100px' },
            tabs: [],
            allItemData: [],
            itemArray: [],
            shouldAnimate: true,
            // 推荐 配置需要显示的id
            recommendeds: [156, 14, 71, 73, 20, 61, 93, 134, 28, 96, 142, 1],
            // 首页显示的分类ID
            cats: [16, 1, 2, 3, 4, 5, 6, 7, 8],
            invite: "",
            siteData: {
                siteName: "",
                siteLink: "zh",
                siteHost: ""
            },
        };
    },
    mounted() {
        this.initAppData();
        this.invite = localStorage.getItem("invite");
        if(window["siteData"]){
            this.siteData = window["siteData"];
        } 
    },
    methods: {
        activateTab(tabIndex, id) {
            this.shouldAnimate = false;
            this.activeTab = tabIndex;
            this.activeCatId = id;
            if (tabIndex === this.tabs.length - 1) {
                // 跳转应用市场
                this.jumpToAppStore();
            } else if (tabIndex === 0) { // 推荐
                this.itemArray.length = 0;
                for (let i = 0; i < this.allItemData.length; i++) {
                    if (this.recommendeds.includes(this.allItemData[i].id) && this.itemArray.length < 12) {
                        this.itemArray.push(this.allItemData[i]);
                    }
                }
            } else {
                this.itemArray.length = 0;
                for (let i = 0; i < this.allItemData.length; i++) {
                    const searchPattern = `|${this.tabs[tabIndex].id}|`;
                    if (this.allItemData[i].catId.includes(searchPattern) && this.itemArray.length < 12) {
                        this.itemArray.push(this.allItemData[i]);
                    }
                }
            }

            // 延时500ms
            setTimeout(() => {
                // this.shouldAnimate = true;
            }, 50);
        },

        jumpToAppStore() {
            if(this.activeCatId > 0) {
                this.$router.push({ name: 'AppStoreHasSite', query: { catId: this.activeCatId} });
            } else {
                this.$router.push({ name: 'AppStoreHasSite'})
            }
            
        },

        handleItemClick(item) {
            if(this.invite){
                window.location.href = `${this.siteData.siteHosts}/${this.siteData.siteLink}/app-store?appid=${item.id}&invite=${this.invite}`;
            } else {
                window.location.href = `${this.siteData.siteHosts}/${this.siteData.siteLink}/app-store?appid=${item.id}`;
            }
        },

        async initAppData() {
            try {
                const [tabResponse, appListResponse] = await Promise.all([
                    axios.get(`${window['siteData'].apiHosts}/api/app/queryCats`),
                    axios.get(`${window['siteData'].apiHosts}/api/app/list`)
                ]);

                // console.log('=========TabData==========', tabResponse.data.data.rows);
                for( const cat of tabResponse.data.data.rows) {
                    if(this.cats.includes(cat.id)) {
                        this.tabs.push(cat)
                    }
                }
                // this.tabs = tabResponse.data.data.rows;
                this.tabs.unshift({ name: '推荐', id: 1000, coverImg: 'ph:fire' });
                this.tabs.push({ name: '浏览全部', id: 0, coverImg: 'gg:arrow-top-right' });

                console.log("====AppData====", appListResponse.data);
                this.allItemData = appListResponse.data.data.rows;

                // 在这里，当两个请求都完成后，激活第一个标签页
                this.activateTab(0, this.tabs[0].id);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

    },
};
</script>

<style scoped>
@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        /* 或者 right: -100%; 如果元素是相对定位的话 */
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* 假设你的div类名为 '.slide-in-right' */
.slide-in-right {
    animation-name: slideInFromRight;
    animation-duration: 600ms;
    /* 动画持续时间为1秒，可自定义 */
    animation-timing-function: ease-out;
    /* 可以更改动画的速度曲线 */
    animation-fill-mode: both;
    /* 保证动画结束后维持最后的关键帧样式 */
    /* 其他样式如position、display等根据需要设置 */
}

/* 对于不支持动画前缀的浏览器添加兼容性写法 */
.slide-in-right {
    -webkit-animation-name: slideInFromRight;
    -webkit-animation-duration: 600ms;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: both;
}

.nav {
    margin: 0 auto;
    border-radius: 50px;
    padding: 5px;
    float: center;
    width: 85%;
    max-width: 1230px;
    text-align: center;
    background-color: #F4F7FF
}

.nav-link {
    color: #464d6A;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.nav-link-img {
    margin-right: 8px;
    font-size: 16px;
}

.nav-pills::-webkit-scrollbar {
    width: 0;
    /* 隐藏水平滚动条 */
    height: 0;
    /* 隐藏垂直滚动条（在这里不需要） */
}

/* 为.nav-pills添加Flex布局 */
.nav-pills {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 5px;
    -ms-overflow-style: none;
    /* IE和Edge隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox隐藏滚动条 */
}

.nav-item {
    flex: 1 0 auto;
    /* 自动分配宽度，允许收缩和扩展 */
    border-radius: 50px;
}

.nav-link.active {
    color: #8166FF;
    border-radius: 50px;
    background-color: #FFFFFF;
}

#index-nav .nav-link.active {
    color: #8166FF !important;
    border-radius: 50px;
    background-color: #FFFFFF !important;
    --tw-shadow: 0 2px 12px 0px rgba(12,18,48,.04);
    --tw-shadow-colored: 0 2px 12px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

#index-nav .nav-link:hover {
    color: #8166FF !important;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50px;
}

#index-nav .nav-item:last-of-type .nav-link {
    color: #8166FF !important;
}

.appstore-container {
    /* width: 66%; */
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
    padding-top: 32px;
}

.container-box-wrapper:hover {
    transform: translateY(-4px);
}

.card {
    --tw-shadow: 0 4px 56px 0px rgba(128,102,255,.08);
    --tw-shadow-colored: 0 4px 56px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    /* width: 300px; */
    height: 225px;
    border: 0px;
    border: 1px solid #F4F7FF;
    border-radius: 16px;
}

.card:hover {
    --tw-shadow: 0 16px 56px 0px rgba(16, 13, 31, 0.08);
    --tw-shadow-colored: 0 6px 56px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-border-opacity: 1;
    border-color: rgb(236 239 255/var(--tw-border-opacity));
    --tw-translate-y: -0.25rem;
}

.line-clamp-2 {
    color: #7F85A4;
    font-size: 13px;
    min-height: 30px;
    overflow: hidden;
    margin-left: 24px;
    margin-top: 10px;
    line-height: 1.3em;
    -webkit-line-clamp: 2;
    margin-right: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.card-talk-btn {
    --tw-text-opacity: 1;
    color: rgb(127 133 163);
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 400;
    margin-left: 24px;
    position: absolute;
    bottom: 20px;
    background: rgb(244 247 255);
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.card-talk-btn i {
    margin-right: 5px;
}

.card-button {
    margin: 0 auto;
    border-radius: 5px;
    position: absolute;
    bottom: 20px;
    width: 85%;
    height: 32px;
    background-color: rgb(128 102 255);
    color: white;
    text-align: center;
    font-size: 13px;
    opacity: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.hidden-btn {
    display: flex;
    justify-content: center;
    align-items: center;

}

.hidden-btn-img {
    width: 14px;
    margin-right: 4px;
}

.card-talk-btn-img{
    width: 12px;
    margin-right: 4px;
}

.card-button:hover .hidden-btn {
    border-radius: 5px;
    background-image: linear-gradient(to right, #8666FF, #B184FF);
}

.card-button i {
    /* margin-right: 5px; */
}

.container-box-wrapper {
    position: relative;
}

.container-box-wrapper>div .hidden-btn {
    /* opacity: 0; */
}

.container-box-wrapper>div:hover .card-button {
    opacity: 1;
}

.mask {
    padding-top: 76px;
    position: absolute;
    top: 0;
    background: white;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    display: flex;
    color: #bf80ff;
    font-size: 14px;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
}

.mask p {
    line-height: 8px;
}

.mask img {
    margin-bottom: 8px;
}

.index-like {
    display: flex;
    align-items: center;
}

.index-like-img {
    margin-right: 6px;
}
</style>