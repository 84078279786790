<template>
  <div class="draft-container">
    <h1>
      <span style="line-height:1">应用权限申请与使用情况说明</span></h1>
    <ul>
      <li>
        <span style="line-height:1">为保障万能AI产品功能实现与安全稳定运行至目的，我们可能会申请或使用操作系统的相关权限；</span></li>
      <li>
        <span style="line-height:1">为保障你的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，你可以根据实际需要对相关权限进行管理；</span></li>
      <li>
        <span style="line-height:1">根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保你及时获悉权限的申请与使用情况；</span></li>
      <li>
        <span style="line-height:1">请你知悉，我们为业务与产品的功能与安全需要，我们可能也会使用第三方及关联方SDK，这些第三方也可能会申请或使用相关操作系统权限；</span></li>
      <li>
        <span style="line-height:1">在使用产品的过程中，您可能会使用第三方开发的H5页面或小程序，这些第三方开发的插件或小程序也可能因业务功能所必需而申请或使用相关操作系统权限；</span></li>
      <li>
        <span style="line-height:1">以下共同适用万能AI的最新版本，如有单独适用的场景或目的将在下列列表说明。</span></li>
    </ul>
    <h3>
      <strong>
        <span style="line-height:1">安卓操作系统应用权限列表</span></strong>
    </h3>
    <table>
      <tr>
        <td colSpan="1" rowSpan="1">
          <strong>权限名称</strong></td>
        <td colSpan="1" rowSpan="1">
          <strong>权限功能说明</strong></td>
        <td colSpan="1" rowSpan="1">
          <strong>使用场景或目的</strong></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1">RECORD_AUDIO录音</td>
        <td colSpan="1" rowSpan="1">使用麦克风录制音频</td>
        <td colSpan="1" rowSpan="1">用于帮助您完成语音输入、完成搜索（语音转语义）等需要使用该权限的相关功能</td></tr>
      <tr>
        <td colSpan="1" rowSpan="1">CAMERA摄像头</td>
        <td colSpan="1" rowSpan="1">使用相机拍摄照片和视频</td>
        <td colSpan="1" rowSpan="1">用于帮助你完成拍摄及发送图片等功能</td></tr>
      <tr>
        <td colSpan="1" rowSpan="1">READ_EXTERNAL_STORAGE读取外置存储器</td>
        <td colSpan="1" rowSpan="1">提供读取手机储存空间内数据的功能</td>
        <td colSpan="1" rowSpan="1">允许App读取存储中的图片、文件等内容，主要用于帮助您上传/发送图片，在本地记录崩溃日志信息（如有）等功能</td></tr>
      <tr>
        <td colSpan="1" rowSpan="1">WRITE_EXTERNAL_STORAGE写入外置存储器</td>
        <td colSpan="1" rowSpan="1">提供写入外部储存功能</td>
        <td colSpan="1" rowSpan="1">允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息</td></tr>
    </table>
    <h3>
      <strong>
        <span style="line-height:1">iOS操作系统应用权限列表</span></strong>
    </h3>
    <table>
      <tr>
        <td colSpan="1" rowSpan="1">
          <strong>权限名称</strong></td>
        <td colSpan="1" rowSpan="1">
          <strong>权限功能说明</strong></td>
        <td colSpan="1" rowSpan="1">
          <strong>使用场景或目的</strong></td>
      </tr>
      <tr>
        <td colSpan="1" rowSpan="1">NSPhotoLibraryAddUsageDescription</td>
        <td colSpan="1" rowSpan="1">向相册中添加内容</td>
        <td colSpan="1" rowSpan="1">允许App写入/下载/保存/修改/删除图片、视频等信息</td></tr>
      <tr>
        <td colSpan="1" rowSpan="1">NSCameraUsageDescription</td>
        <td colSpan="1" rowSpan="1">使用摄像头</td>
        <td colSpan="1" rowSpan="1">用于帮助你完成拍摄及发送图片等功能</td></tr>
      <tr>
        <td colSpan="1" rowSpan="1">NSMicrophoneUsageDescription</td>
        <td colSpan="1" rowSpan="1">使用麦克风</td>
        <td colSpan="1" rowSpan="1">用于帮助您完成语音输入、完成搜索（语音转语义）等需要使用该权限的相关功能</td></tr>
    </table>
  </div>
</template>

<style scoped>
    body{
        line-height:1;
    }
    table{
        border-collapse:collapse;
        border-spacing:0;
    }
    ul{
        list-style-type:square;
    }
    strong{
        font-weight:700;
    }
    .draft-container{
        box-sizing:border-box;
        height:100%;
        line-height:1.5;
        color:#000;
        font-size:14px;
        padding-bottom:25px;
        padding-left:25px;
        padding-right:25px;
        padding-top:25px
    }
    .draft-container p{
        margin-bottom:9px;
    }
    .draft-container table{
        width:100%;
    }
    .draft-container tr{
        text-align:center;
    }
    .draft-container td{
        border:1px solid#e4e4e5;
    }
    .draft-container a{
        color:#3370ff;
    }
    .draft-container a:hover{
        color:#0000EE;
    }
    .draft-container h1,h2,h3,h4,h5,h6{
        color:#000;
        font-weight:600;
    }
    .draft-container h1{
        margin-bottom:15px;
        margin-top:15px;
        font-size:22px;
    }
    .draft-container h2{
        margin-bottom:13px;
        margin-top:13px;
        font-size:19px;
    }
    .draft-container h3{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h4{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px
    }
    .draft-container h5{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container h6{
        margin-bottom:8px;
        margin-top:8px;
        font-size:17px;
    }
    .draft-container ul,.draft-container ol{
        padding-left:16px;
    }
    .draft-container ul ul,.draft-container ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul,.draft-container ol ol ol{
        padding-left:16px;
    }
    .draft-container ul ul ul ul,.draft-container ol ol ol ol{
        padding-left:16px;
    }
    .draft-container ol{
        list-style-type:decimal;
    }
    .draft-container ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol{
        list-style-type:decimal;
    }
    .draft-container ol ol ol ol{
        list-style-type:decimal;
    }
    h3 {
        padding-top: 1.2em;
    }
</style>